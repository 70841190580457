
import React from "react";
import { Link } from 'react-router-dom';
import { Col, Row, Card, Image} from 'react-bootstrap';

import {
  windowsRunningIcon,
  windowsStoppedIcon,
  windowsHibernatedIcon,
  windowsBuildingIcon,
} from "./../../assets/images";

import { HighlightedProductImage } from "./../";


const GenericStatisticWidget: React.FunctionComponent<any> = (props:any) => {
  const { header, description, numberCount } = props;

  return (
    <>
      <Card className="card mb-3 new-project-card shadow me-3 ps-0" style={{minWidth: "200px", maxWidth: "200px"}}>
        <Row className="row">
          <Col className="col-9  pe-0">
            <Card.Body className="card-body ps-2 pe-1 py-2" >
              <div className="card-header-0">{header}<br/></div>
              <div className="card-subheader-0"> {description}</div>
            </Card.Body>
          </Col>
          <Col className="col-3 px-1" >
            <Card.Body className="card-body px-1 pt-2 pb-0">
              <div className="card-value-0 pr-3">{numberCount}</div>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  );
};

const HighlightedResource: React.FunctionComponent<any> = (props:any) => {
  const { header, description, linkPath, status } = props;
 
  return (
    <>
      <Card className="mb-3 new-project-card shadow me-3 ps-0" style={{minWidth: "252px", maxWidth: "252px"}}>
        <Link to={linkPath}>
          <Row className="g-0">
            <Col className="col-md-1 new-project-card-sidebar npc-sidebar-product" style={{maxWidth: "10px"}}></Col>
            <Col className="ps-0">
                <div className="row g-0 p-1">
                  <div className="col-2-5 ps-1 pt-1 mb-0" >
                    {(status === "hibernation") || (status === "hibernating") ? 
                      <Image src={windowsHibernatedIcon}></Image>
                      :
                      null
                    }
                    {(status === "running") || (status === "starting") ? 
                      <Image src={windowsRunningIcon}></Image>
                      :
                      null
                    }
                    {(status === "stopped") || (status === "stopping") ? 
                      <Image src={windowsStoppedIcon}></Image>
                      :
                      null
                    }
                    {(status === "building")  ? 
                      <Image src={windowsBuildingIcon}></Image>
                      :
                      null
                    }
                  </div>
                  <div className="col-9-5 ps-2 nowrap" style={{maxWidth: "200px"}}>
                    {/* <a className="nodecor"> */}
                      <h5 className="card-header-0 mb-0">{header}</h5>
                      <h4 className="card-subheader-0 mb-0">{description}&nbsp;</h4>
                    {/* </a> */}
                  </div>
                </div>            
            </Col>
          </Row>
        </Link>
      </Card>
    </>
  );
};

const HighlightedProduct: React.FunctionComponent<any> = (props:any) => {
  const { header, description, linkPath, status, product, system } = props;
 
  return (
    <>
      <Card className="mb-3 new-project-card shadow me-3 ps-0" style={{minWidth: "252px", maxWidth: "252px"}}>
        <Link to={linkPath}>
          <Row className="g-0">
            <Col className="col-md-1 new-project-card-sidebar npc-sidebar-product" style={{maxWidth: "10px"}}></Col>
            <Col className="ps-0">
                <div className="row g-0 p-1">
                  <div className="col-2-5 ps-1 pt-1 mb-0" >
                    <HighlightedProductImage
                      product={product}
                      status={status}
                      system={system}
                    />
                  </div>
                  <div className="col-9-5 ps-2 nowrap" style={{maxWidth: "200px"}}>
                    {/* <a className="nodecor"> */}
                      <h5 className="card-header-0 mb-0">{header}</h5>
                      <h4 className="card-subheader-0 mb-0">{description}&nbsp;</h4>
                    {/* </a> */}
                  </div>
                </div>            
            </Col>
          </Row>
        </Link>
      </Card>
    </>
  );
};

const HighlightedProductDetail: React.FunctionComponent<any> = (props:any) => {
  const { header, description, linkPath, status, product, system } = props;
 
  return (
    <>
      <Card className="mb-3 new-project-card shadow me-3 ps-0" style={{minWidth: "252px", maxWidth: "252px"}}>
        <Link to={linkPath}>
          <Row className="g-0">
            <Col className="col-md-1 new-project-card-sidebar npc-sidebar-product" style={{maxWidth: "10px"}}></Col>
            <Col className="ps-0">
                <div className="row g-0 p-1">
                  <div className="col-2-5 ps-1 pt-1 mb-0" >
                    <HighlightedProductImage
                      product={product}
                      status={status}
                      system={system}
                    />
                  </div>
                  <div className="col-9-5 ps-2 nowrap" style={{maxWidth: "200px"}}>
                    {/* <a className="nodecor"> */}
                      <h5 className="card-header-0 mb-0">{header}</h5>
                      <h4 className="card-subheader-0 mb-0">{description}&nbsp;</h4>
                    {/* </a> */}
                  </div>
                </div>            
            </Col>
          </Row>
        </Link>
      </Card>
    </>
  );
};

const HighlightedProfile: React.FunctionComponent<any> = (props:any) => {
  const { header, description, image, linkPath } = props;
 
  return (
    <>
      <Card className="mb-3 new-project-card shadow me-3 ps-0" style={{minWidth: "252px", maxWidth: "252px"}}>
        <Link to={linkPath}>
          <Row className="g-0">
            <Col className="col-md-1 new-project-card-sidebar npc-sidebar-profile" style={{maxWidth: "10px"}}></Col>
            <Col className="ps-0">
                <div className="row g-0 p-1">
                  <div className="col-2-5 ps-1 pt-1 mb-0" >
                    <Image src={image} className="k-mh-40"></Image>
                  </div>
                  <div className="col-9-5 ps-2 nowrap" style={{maxWidth: "200px"}}>
                    {/* <a className="nodecor"> */}
                      <h5 className="card-header-0 mb-0">{header}</h5>
                      <h4 className="card-subheader-0 mb-0">{description}&nbsp;</h4>
                    {/* </a> */}
                  </div>
                </div>            
            </Col>
          </Row>
        </Link>
      </Card>
    </>
  );
};

const HighlightedProject: React.FunctionComponent<any> = (props:any) => {
  const { header, description, image, linkPath } = props;
 
  return (
    <>
      <Card className="mb-3 new-project-card shadow me-3 ps-0" style={{minWidth: "252px", maxWidth: "252px"}}>
        <Link to={linkPath}>
          <Row className="g-0">
            <Col className="col-md-1 new-project-card-sidebar npc-sidebar-project" style={{maxWidth: "10px"}}></Col>
            <Col className="ps-0">
                <div className="row g-0 p-1">
                  <div className="col-2-5 ps-1 pt-1 mb-0" >
                    <Image src={image} className="k-mh-40"></Image>
                  </div>
                  <div className="col-9-5 ps-2 nowrap" style={{maxWidth: "200px"}}>
                    {/* <a className="nodecor"> */}
                      <h5 className="card-header-0 mb-0">{header}</h5>
                      <h4 className="card-subheader-0 mb-0">{description}&nbsp;</h4>
                    {/* </a> */}
                  </div>
                </div>            
            </Col>
          </Row>
        </Link>
      </Card>
    </>
  );
};





export {
  GenericStatisticWidget, 
  HighlightedResource,
  HighlightedProfile,
  HighlightedProject,
  HighlightedProduct,
  HighlightedProductDetail
};

