import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Card } from 'react-bootstrap';


import {
  addItemBtn,
  resourceAddIcon,
  productMediaDeleteIcon,

} from "./../../assets/images";

import {
  AdminGroupsContentEditFormPane,
  AdminUsersContentEditFormPane,
  PasswordPanelList,

  ResourceAccountPasswordFormPane,

  ProductToolsPasswordItem,
  DropdownAddButton
} from "./../../components";






const ResourceVaultContentTab: React.FunctionComponent<any> = (props:any) => {
  const { 
    vault, 
    vaultHandlers,
    // preSelectedSecretId,
    // setPreSelectedSecretId,
    // vaultSecretEditSubmitError 
  } = props;

  const [secrets, setSecrets] = useState<any>([]);
  const [selectedSecret, setSelectedSecret] = useState<any>(null)


  const getVaultSecrets = async() => {
    const vaultHeader = vault && await vault.getDecryptedVaultHeader();
    console.log("Vault Header: ", vaultHeader)
    const secretsData = vaultHeader.listSecrets();
    console.log("Secxrets DATAS : ", secretsData)
    if(secretsData){
      setSecrets(secretsData);

      if(vaultHandlers && vaultHandlers.preSelectedSecretId){
        let secretData = secrets.find((secret: any)  => secret.secretId === vaultHandlers.preSelectedSecretId);
        
        secretData.password = vault && await vault.getAndDecryptItemFromVault(vaultHandlers.preSelectedSecretId);
        
        setSelectedSecret(secretData);
  
        console.log("Selected Previous Secret Found!: ", secretData)
      } else {
        setSelectedSecret(null);
      }
    }
  }

  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    getVaultSecrets();

  }, [vault]);


  const handleCreateSecret = async(event: any) => {
    event.preventDefault();
    
    await vaultHandlers.handlePostVaultPassword()
    
  }

  const handleDeleteSecret = async(event: any, secretId: any) => {
    event.preventDefault();
  
    await vaultHandlers.handleDeleteVaultPassword({
      body: {
        secretId
      }
    });
  }

  const handleSelectSecret = async(event: any, secretId: string ) => {
    event.preventDefault()
    console.log("SEcret Selected: ", secretId);

    if(secrets){
      let secretData = secrets.find((secret: any)  => secret.secretId === secretId);
      secretData.password = vault && await vault.getAndDecryptItemFromVault(secretId);
      
      setSelectedSecret(secretData);

      console.log("Selected Secret Found!: ", secretData)
    }
  }


  console.log("Resource Content Tab: Vault: ", vault );
  console.log("Resource Content Tab: Secrets: ", secrets );
  console.log("Selected Secret: ", selectedSecret)

  return (
    <>
      <Row>
        <Col className="col-3 mw-310">
          <Card className='hmh-100vh-280px'>
            <Card.Header className='p-1'>
              <Row xs="auto" className='ms-0 me-0'>
                <Col className="ps-1 col-auto me-auto">Passwords</Col>
                <Col className=''><img src={addItemBtn} alt={"Icon"} className="addIcon dropdown-tab-icon" onClick={handleCreateSecret}/></Col>
              </Row>
            </Card.Header>
            <Card.Body className='p-0 k-card-3'>
                { secrets && secrets.map((secret: any, index: number) => {
                  console.log("PASSWORD LOOPER : ", secret);
                  return (
                    <Row className="m-0">
                      <Col className="p-0">
                      <PasswordPanelList key={`prodPassword-${index}`}
                        id={secret.secretId}
                        title={secret.title}
                        utsModified={secret.modified}
                        headline={secret.headline}
                        passwordUrl={secret.url}
                        passwordID={secret.id}
                        edit='false'
                        handlePasswordSelect={handleSelectSecret}
                        handlePasswordDelete={handleDeleteSecret}
                      />
                      </Col>
                    </Row>
                  )
                })}
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-9 hmh-100vh-280px">
          <Card className='hmh-100vh-280px'>
            {selectedSecret && selectedSecret.secretId ? 
            <>
              <Card.Header className='p-1'>
                <Row xs="auto" className='ms-0 me-0'>
                  <Col className="ps-1 col-auto me-auto">Password</Col>
                  {/* <Col className=''><img src={addItemBtn} alt={"Icon"} className="addIcon dropdown-tab-icon" onClick={(event: any ) => console.log("password saved...")}/></Col> */}
                </Row>
              </Card.Header>
              <Card.Body className='p-0 k-card-3'>
                <Row className='m-0 pt-3 justify-content-center'>
                  <Col className='m-0 col-6 justify-content-center'>
                    {/* <Row className='m-0 justify-content-center pb-3'><Col className='col-6 m-0 p-0 pe-3 k-txt-aln-e'><img src="./../images/twitter.com.png" alt={"Icon"} className="k-mmh-50"/> </Col><Col className='col-6 m-0 p-0 pt-2 header-black-22'>{vaultNew.title}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>username </Col><Col className='col-6 m-0 p-0'> {vaultNew.username}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>password </Col><Col className='col-6 m-0 p-0'> {vaultNew.password}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'> </Col><Col className='col-6 m-0 p-0'> </Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>website </Col><Col className='col-6 m-0 p-0'> {vaultNew.url}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>OTP/2FA </Col><Col className='col-6 m-0 p-0'> {vaultNew.otp}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'> </Col><Col className='col-6 m-0 p-0'> </Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>modified on</Col><Col className='col-6 m-0 p-0'>{vaultNew.modified}</Col></Row>
                    <Row className='m-0'><Col className='col-6 m-0 p-0 pe-3 mb-2 k-txt-aln-e'>created on</Col><Col className='col-6 m-0 p-0'>{vaultNew.created}</Col></Row> */}
                    <ResourceAccountPasswordFormPane
                      accountPassword={selectedSecret}
                      editAccountPasswordSubmitError={vaultHandlers.vaultSecretEditSubmitError}
                      handleAccountPasswordEditSubmit={vaultHandlers.handleEditVaultPassword}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </>
            :
              < Card.Header>
                Please Select A Password
              </Card.Header>
            }
          </Card>
        </Col>
      </Row>
    </>
  );
}


const AdminUsersContentTab: React.FunctionComponent<any> = (props:any) => {
  const { 
    users,
    groups,
    selectedUser, 
    mediaControlHandlers,
    editUserSubmitError,
    handleUserEditSubmit,
    handleShowAddUserModal, 
    handleAdminSelectObject 
  } = props;

  // console.log("Admin Users Cont TAB: Selected USER : ", selectedUser);

  return (
    <>
      <Row >
        <Col className="col-6 p-0 ">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              <div className="card shadow minh-400-mh-100vh">
                <Row className='m-0 pt-2'>
                  <Col className="col-11">
                    <h5>Users</h5>
                  </Col>
                  <Col className="col-1">
                    <button type="button" onClick={handleShowAddUserModal} className="k-generic-btn-nobg">
                      <img src={resourceAddIcon} alt={"Admin Users Add Buttton"}/>
                    </button>
                  </Col>
                </Row>
                <Row className='k-of-y-scroll m-0 ps-2'>
                  <Table className='k-brd-transparent'>
                    <thead className=''>
                      <tr>
                        <th>Username</th>
                        <th>Display Name</th>
                        <th>Role</th>
                        <th>MFA</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody className='k-t-body-scroll-admin'>
                      {users && users.map((user: any, index: number) => {
                        return (
                          <tr key={`admin-users-${index}`}>
                            <td 
                              onClick={(event)=> handleAdminSelectObject(event, {type: "USER", id: user.id})}
                              style={{ cursor: "pointer"}}
                            >
                              {user.id}
                            </td>
                            <td>{user.id}</td>
                            <td>{user.role}</td>
                            <td>Yes</td>
                            <td> 
                              <Row>
                                
                                <Col md={1}></Col>
                                <Col md={1}>
                                  <div style={{ cursor: "pointer"}} onClick={(event: any)=>{ mediaControlHandlers.handleUsersListDelete(event, {id: user.id, name: user.id})}}>
                                    <span>
                                      <img className="k-ctrlImg" src={productMediaDeleteIcon} alt={"Delete Icon"}/>
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>


        <Col className="col-6 p-0">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              {(selectedUser && selectedUser.type === "USER") ? (
                <>
                  <div className="card shadow minh-400">
                    <Row className='m-0 pt-2'>
                      <Col className='col-12'>
                        <h5>User Detail</h5>
                      </Col>
                    </Row>
                    <AdminUsersContentEditFormPane
                      userContent={selectedUser.content}
                      groups={groups}
                      editUserSubmitError={editUserSubmitError}
                      handleUserEditSubmit={handleUserEditSubmit}
                    />
                  </div> 
                </>
                )
                :
                (<>
                  <div className="card shadow minh-400">
                    <Row className='m-0 pt-2'>
                        <Col className='col-12'>
                          <h5>Select a User</h5>
                        </Col>
                      </Row>
                  </div>
                </>)
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </>

  );
}

const AdminGroupsContentTab: React.FunctionComponent<any> = (props:any) => {
  const { 
    groups,
    users, 
    selectedGroup, 
    mediaControlHandlers,
    editGroupSubmitError,
    handleGroupEditSubmit,
    handleShowAddGroupModal, 
    handleAdminSelectObject 
  } = props;

  console.log("Admin Group Cont TAB: Selected Group : ", selectedGroup);
  // console.log("profile.Identity tab: ", props)

  return (
    <>
      <Row >
        <Col className="col-6 p-0">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              <div className="card shadow minh-400-mh-100vh">
                <Row className='m-0 pt-2'>
                  <Col className="col-11">
                    <h5>Groups</h5>
                  </Col>
                  <Col className="col-1">
                    <button onClick={handleShowAddGroupModal} type="button" className="k-generic-btn-nobg">
                      <img src={resourceAddIcon} alt={"Admin Groups Add Buttton"}/>
                    </button>
                  </Col>
                </Row>
                <Row className='k-of-y-scroll m-0 ps-2'>
                  <Table className='k-brd-transparent'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th colSpan={2}>Description</th>
                        <th colSpan={2}>Created On</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody className='k-t-body-scroll-admin'>
                    {groups && groups.map((group: any, index: number) => {
                        return (
                          <tr key={`admin-groups-${index}`}>
                            <td 
                              onClick={(event)=> handleAdminSelectObject(event, {type: "GROUP", id: group.id})}
                              style={{ cursor: "pointer"}}
                            >
                              {group.name}
                            </td>
                            <td colSpan={2}>{group.description}</td>
                            <td colSpan={2}>---</td>
                            <td> 
                              <Row>
                                
                                <Col md={1}></Col>
                                <Col md={1}>
                                  <div style={{ cursor: "pointer"}} onClick={(event: any)=>{ mediaControlHandlers.handleGroupsListDelete(event, {id: group.id, name: group.name})}}>
                                    <span>
                                      <img className="k-ctrlImg" src={productMediaDeleteIcon} alt={"Delete Icon"}/>
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )
                      })}                    
                    </tbody>
                  </Table>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>

        <Col className="col-6 p-0">
          <Row className="m-0 mt-2">
            <Col className="col-12">
            {(selectedGroup && selectedGroup.type === "GROUP") ? (
                <>
                  <div  className="card shadow minh-400">
                    <Row className='m-0 pt-2'>
                      <Col className='col-12'>
                        <h5>Group Detail</h5>
                      </Col>
                    </Row>
                    <AdminGroupsContentEditFormPane
                      groupContent={selectedGroup.content}
                      users={users}
                      editGroupSubmitError={editGroupSubmitError}
                      handleGroupEditSubmit={handleGroupEditSubmit}
                    />

                  </div>  
                </>)
                :
                (<>
                  <div  className="card shadow minh-400">
                    <Row className='m-0 pt-2'>
                        <Col className='col-12'>
                          <h5>Select Group</h5>
                          </Col>
                      </Row>
                  </div>
                </>)
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </>

  );
}

const AdminNotificationsContentTab: React.FunctionComponent<any> = (props:any) => {
  const { notifications, selectedNotification, handleAdminSelectObject } = props;

  // console.log("Admin Notifications Cont TAB: Selected Notifications : ", selectedNotification);
  // console.log("profile.Identity tab: ", props)

  return (
    <>
      <Row>
        <Col className="col-7 ">
          <Row>
            <Col className="col-12">
              <div className="card shadow">
                <br/>
                <Row>
                  <Col className="col-11">
                    <h4>System Notifications</h4>
                  </Col>
                  {/* <Col className="col-1">
                    <img src={resourceAddIcon} alt={"Admin Groups Add Buttton"}/>
                  </Col> */}
                </Row>
                <br/>
                <Table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Severity</th>
                      <th>User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notifications && notifications.map((notification: any, index: number) => {
                      return (
                        <tr key={`admin-groups-${index}`}>
                          <td 
                            onClick={(event)=> handleAdminSelectObject(event, {type: "NOTIFICATION", id: notification.id})}
                            style={{ cursor: "pointer"}}
                          >
                            {notification.date}
                          </td>
                          <td colSpan={2}>{notification.description}</td>
                          <td>INFO</td>
                          <td colSpan={2}>Admin</td>
                        </tr>
                      )
                    })}
                    <tr>
                      <td>03/03/2022</td>
                      <td colSpan={2}>New security group created - RED-1</td>
                      <td>INFO</td>
                      <td colSpan={2}>Admin</td>
                    </tr>
                    <tr>
                      <td>RED-1</td>
                      <td colSpan={2}>Password reset request for Henry</td>
                      <td>ALERT</td>
                      <td colSpan={2}>Henry</td>
                    </tr>
                    
                  </tbody>
                </Table>

              </div>
            </Col>
          </Row>
        </Col>

        <Col className="col-5">
          <Row>
            <Col className="col-12">
            {(selectedNotification && selectedNotification.type === "NOTIFICATION")? (
                <>
                  <div  className="card shadow">
                    <h4>SELECTED NOTIFICATION stuff here</h4>
                    <br/>
                    <Row>
                      <Col className="col-4">
                        Note Name: {selectedNotification.content.name}
                      </Col>
                      <Col className="col-4">
                      </Col>
                      <Col className="col-4">
                      </Col>
                    </Row>
                    <br/>
                    <Row>
                      <Col className="col-4">
                      </Col>
                      <Col className="col-4">
                      </Col>
                      <Col className="col-4">
                      </Col>
                    </Row>
                  </div> 
                </>)
                :
                (<>
                  <div  className="card shadow">
                    <h4>Select Notification</h4>
                  </div>
                </>)
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </>

  );
}


const ProductToolsVaultTab: React.FunctionComponent<any> = (props: any)  => {

  const {
    productDetails, 
    vault,
    preSelectedSecretId,
    vaultSecretEditSubmitError,
    vaultHandlers,
  } = props;

  const [secrets, setSecrets] = useState<any>([]);
  const [selectedSecret, setSelectedSecret] = useState<any>(null)


  const getVaultSecrets = async() => {
    console.log("Product Toold Vault: ", vault);
    // if(vault){
      
    // }
    const vaultHeader = vault && await vault.getDecryptedVaultHeader();
      console.log("Vault Header: ", vaultHeader)
      const secretsData = vaultHeader.listSecrets();
      console.log("Secxrets DATAS : ", secretsData)
      if(secretsData){
        setSecrets(secretsData);

        if(preSelectedSecretId){
          let secretData = secrets.find((secret: any)  => secret.secretId === preSelectedSecretId);
          
          secretData.password = vault && await vault.getAndDecryptItemFromVault(preSelectedSecretId);
          
          setSelectedSecret(secretData);
    
          console.log("Selected Previous Secret Found!: ", secretData)
        } else {
          setSelectedSecret(null);
        }
      }
  }

  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    getVaultSecrets();

  }, [vault]);

  // console.log("Vault: ")
  const handleCreateSecret = async(event: any) => {
    event.preventDefault();
    // await handleProfileAddPassword({body: '<h1> New Note </h1>', id: profileID, headline: 'New Note', title: 'New Note'});
    await vaultHandlers.handlePostProductVaultPassword()
    
  }

  const handleDeleteSecret = async(event: any, secretId: any) => {
    event.preventDefault();
    console.log("retreiving product secret: ....", secretId)
    await vaultHandlers.handleDeleteProductVaultPassword({
      body: {
        secretId
      }
    });
  }

  const handleSelectSecret = async(event: any, secretId: string ) => {
    // event.preventDefault();
    console.log("SEcret Selected: ", secretId);

    if(secrets){
      let secretData = secrets.find((secret: any)  => secret.secretId === secretId);
      secretData.password = vault && await vault.getAndDecryptItemFromVault(secretId);
      
      setSelectedSecret(secretData);

      console.log("Selected Secret Found!: ", secretData)
    }
  }


  console.log("PRODUCT Vault Tab: Vault: ", vault );
  console.log("PRODUCT Vault Tab: Secrets: ", secrets );
  console.log("PRODUCT Vault Tab: Selected Secret: ", selectedSecret);
  console.log("PRODUCT Vault Tab: Desktop Details: ", productDetails);

  return (
      <>
        <Row className="width-100-PC m-0">
          <Col className="ms-4 ps-0 pe-0">
            <Row className="header-black-22">Passwords</Row>
            <Row className="subheader-black-22 mt-m10">Manage your Secrets</Row>
          </Col>
          <Col className="ms-0 mt-2 ms-2 p-0">
            <DropdownAddButton
              btnClass={"ps-0 pe-0 pt-0 ml-95"}
              clickHandler={handleCreateSecret}
            />
          </Col>
        </Row>
        <div className="scrolling-topcontainer">
          <div className="scrolling-container pt-2">
            { secrets && secrets.map((secret: any, index: number) => {
              
              return (
                <Row className="m-0" key={`secret-index-${index}`}>
                  <Col className="pb-0 ps-3 pe-3">
                    <ProductToolsPasswordItem 
                      onClick={(event: any) => handleSelectSecret(event, secret.secretId)}
                      key={`prodPass-${secret.secretId}`}
                      secret={secret}

                      selectedSecret={selectedSecret}
                      vaultSecretEditSubmitError={vaultSecretEditSubmitError}

                      handlePasswordSelect={handleSelectSecret}
                      handlePasswordDelete={handleDeleteSecret}
                      handlePasswordEdit={vaultHandlers.handleEditProductVaultPassword}
                    />
                    </Col>
                </Row>
              )
            })}
          </div>
        </div>
      </>
  )
}


export {

  ResourceVaultContentTab,

  // Admin Content
  AdminUsersContentTab,
  AdminGroupsContentTab,
  AdminNotificationsContentTab,

  // Product 
  ProductToolsVaultTab
};