import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Container, Button, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  resourceAddIcon,
} from "./../../assets/images";

import {
  AdminGroupAddUsersDropdownMenu,
  AdminGroupAddUsersDropdownToggle,
  SelectGroupDropdownMenu,
  SelectGroupDropdownToggle,
  TwoDigitCountdown
} from "./../../components";

import {
  variables,
  helpers
} from "./../../utilities";

const AdminGroupsContentEditFormPane: React.FunctionComponent<any> = (props: any) => {
  const [editFormBody, setEditFormBody] = useState<any>({});
  const [isGroupNameValid, setGroupNameValid] = useState(true);
  const [isGroupDescriptionValid, setGroupDescriptionValid] = useState(true);
  const [groupUsers, setGroupUsers] = useState<any>([]);
  
  const [isFormValid, setFormValid] = useState(false);
  const [isFormChanged, setFormChanged] = useState(false);
  
  const { 
    groupContent, 
    users, 
    editGroupSubmitError,
    handleGroupEditSubmit
  } = props;

  useEffect(() => {
    setFormChanged(false);
    setEditFormBody({...groupContent});
    setGroupNameValid(true);
    setGroupDescriptionValid(true);
    setGroupUsers((groupContent && groupContent.users) || []);
  }, [groupContent]);

  const handleInputChange = (event: any) => {
    // Toggle Form has Changed
    setFormChanged(true);

    //
    if(event.target.name === "name") {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.GROUP.NAME.test(event.target.value)) {
        setGroupNameValid(true);
      } else {
        setGroupNameValid(false);
      }
    };

    if(event.target.name === "description") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.GROUP.DESCRIPTION.test(event.target.value) || event.target.value === "") {
        setGroupDescriptionValid(true);
      } else {
        setGroupDescriptionValid(false);
      }
    };


    setEditFormBody({
      ...editFormBody,
      [event.target.name]: event.target.value,
    });


    if (isGroupNameValid && isGroupDescriptionValid ){
      setFormValid(true)
    } else {
      setFormValid(false)
    }

  }


  const addGroupUser = (event: any, userId: any) => {
    event.preventDefault();

    setGroupUsers([
      ...groupUsers,
      userId
    ]);

    setFormChanged(true);
  }

  const removeGroupUser = (event: any, userId: number) => {
    event.preventDefault();
      
    setGroupUsers([...groupUsers.filter((user: any) => user !== userId)]);
    
    setFormChanged(true);
  }


  const validateForm = () => {
    return;
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();

    console.log("Group edit body: ", editFormBody)
    console.log("Group edit body: ", groupContent)
    console.log("Group edit body: ", groupUsers)

    if (isGroupNameValid && isGroupDescriptionValid){

      await handleGroupEditSubmit({body: {
        ...editFormBody,
        users: [
          ...groupUsers
        ]
      }});

    } else {
      console.log("FORM NOT VALID... Won't make it so");
    }
  }

  // console.log("Group Users ", groupUsers)
  console.log("selected User Group Content : ", groupContent);

  return (
    <>
   
        <Form onSubmit={handleFormSubmit} key={groupContent.id} className='m-2'>
          <Container className="p-0">
            <Row className="m-0">
              <Col className="col-12">
                <Row className="m-0">
                  <Col className="col-4 ps-0">
                    <Row className="m-0 k-inputText15">
                      Name
                    </Row>
                    <Row className="m-0">
                      <Form.Group className="p-0 " controlId="paneGroupEditForm.Name">
                        <Form.Control className="p-0 k-inputText k-inputText15-light" type="text" defaultValue={groupContent.name} name='name' onChange={handleInputChange}/>
                      </Form.Group>
                    </Row>
                  </Col>
                  <Col className="col-8">
                    <Row>
                      Description
                    </Row>
                    <Row>
                      <Form.Group className="p-0 " controlId="paneGroupEditForm.Description">
                        <Form.Control className="p-0 k-inputText k-inputText15" type="text" defaultValue={groupContent.description}  name='description' onChange={handleInputChange} />
                      </Form.Group>
                    </Row>
                  </Col>
                </Row>
                  <Row>
                    <Col className="col-11 p-0">
                      {isGroupNameValid ?  null: <small style={{color: "red"}}>Enter a valid Group Name. {"(5 - 25 Alphabets and Numbers only accepted)"}</small>}
                      {isGroupDescriptionValid ?  null : <small style={{color: "red"}}>Enter a valid Group Description. {"(Alphabets, Numbers, space and charcaters[-., ()/] accepted)"}</small>}
                    </Col>
                  </Row>
              </Col>
            </Row>
            <Row><Col className='ps-4 pe-4'><hr/></Col></Row>
            <Row className="m-0">
              <Col className="col-12 pb-3">
                <Row className="m-0">
                  <Col className="col-12 ps-0">
                    <Row className="m-0 p-0">
                      <Row className="m-0 p-0">
                        <Col className="col-4 ps-0 k-inputText15">
                            Username
                        </Col>
                        <Col className="col-5 ps-1 k-inputText15">
                            Date Added
                        </Col>
                        <Col className="col-3 k-inputText15">
                          Action
                        </Col>
                      </Row>

                      {groupUsers && groupUsers.map((user: any, index: number) => (
                      <Row className="m-0 p-0" key={`paneGroupEditForm.Users.${index}`}>
                        <Col className="col-4 k-inputText k-inputText15-light ps-0">
                          {user}
                        </Col>
                        <Col className="col-5 k-inputText k-inputText15-light ps-1">
                          ---
                        </Col>
                        <Col className="col-3">
                          <div style={{ cursor: "pointer"}} onClick={(event: any) => removeGroupUser(event, user)}>
                            <span>
                              remove
                              {/*<img className="k-ctrlImg" src={productMediaDeleteIcon} alt={"Delete Icon"}/>*/}
                            </span>
                          </div>
                        </Col>
                      </Row>))}
                    </Row>

                    <Row>
                      <Col className="col-11 p-0 ">
                        <Dropdown>
                          <Dropdown.Toggle as={AdminGroupAddUsersDropdownToggle}>
                            <Row className='m-0 pt-1'>
                              <Col className="col-1 pe-0">
                              <img alt="name icon" src={resourceAddIcon} className="pe-1 k-mmh-15 k-pb-2"/>
                            </Col>
                            <Col className="col-11 p-0 k-inputText15 k-ml--10 k-pt-1">
                              Add user
                            </Col>
                            </Row>
                          </Dropdown.Toggle>
                          <Dropdown.Menu as={AdminGroupAddUsersDropdownMenu} >
                            {users && users.map((user: any, index: number) => {
                              return (groupUsers && groupUsers.includes(user.id)) ?   
                                null :
                                (<Dropdown.Item className="k-inputText12" key={`paneGroupEditForm.Dropdown.${index}`} onClick={(event: any) => addGroupUser(event, user.id)}>{user.id}</Dropdown.Item>)
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                        
                      </Col>
                    </Row>
                      
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="m-0 pb-3">
              <Row className="pe-0">
                <Col className="px-0 col-9 col-auto"></Col>
                <Col className="col p-0 ">
                  <div className="pt-2">
                    <Button variant="success" 
                      type="submit"
                      disabled={!isGroupNameValid || !isGroupDescriptionValid || !isFormChanged }
                        size="sm" className="k-btn">
                      Update
                    </Button>
                  </div>
                </Col>
              </Row>
              {editGroupSubmitError? (<>
                <small style={{color: "red"}}>{editGroupSubmitError}</small>
              </>): null}
            </Row>
          </Container>
        </Form>

    </>
  );
}

const AdminUsersContentEditFormPane: React.FunctionComponent<any> = (props: any) => {
  const [editFormBody, setEditFormBody] = useState<any>({});
  const [isUsernameValid, setUsernameValid] = useState(true);
  const [isDisplayNameValid, setDisplayNameValid] = useState(true);
  const [isRoleValid, setRoleValid] = useState(true);
  const [isUserBioValid, setUserBioValid] = useState(true);
  const [isUserPasswordValid, setUserPasswordValid] = useState(true);
  // const [isUserPasswordConfirmValid, setUserPasswordConfirmValid] = useState(false);
  const [userGroupIds, setUserGroupIds] = useState<any>([]);
  const [securityGroups, setSecurityGroups] = useState<any>([]);
  
  const [isFormValid, setFormValid] = useState(false);
  const [isFormChanged, setFormChanged] = useState(false);
  
  const { 
    userContent, 
    groups, 
    editUserSubmitError,
    handleUserEditSubmit
  } = props;

  useEffect(() => {
    setFormChanged(false);
    setEditFormBody({...userContent});
    setUsernameValid(true);
    setDisplayNameValid(true);
    setUserBioValid(true);
    setRoleValid(true);
    // setGroupDescriptionValid(true);
    setSecurityGroups(groups)
    setUserGroupIds((userContent && userContent.security) || []);
  }, [userContent.id]);

  const handleInputChange = (event: any) => {
    // Toggle Form has Changed
    setFormChanged(true);

    if(event.target.name === "displayName") {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.USER.DISPLAY_NAME.test(event.target.value)) {
        setDisplayNameValid(true);
      } else {
        setDisplayNameValid(false);
      }
    };

    if(event.target.name === "password") {
      if (variables.DEFAULT_FORM_VALIDATION.USER.PASSWORD.test(event.target.value) || (event.target.value === "")) {
        setUserPasswordValid(true);
      } else {
        setUserPasswordValid(false);
      }
    };

    // if(event.target.name === "passwordConfirm") {
    //   if (editFormBody["password"] === event.target.value) {
    //     setUserPasswordConfirmValid(true);
    //   } else {
    //     setUserPasswordConfirmValid(false);
    //   }
    // };

    if(event.target.name === "role") {
      if ( event.target.value !== "") {
        setRoleValid(true);
      } else {
        setRoleValid(false);
      }
    };

    if(event.target.name === "bio") {
      if (variables.DEFAULT_FORM_VALIDATION.USER.BIO.test(event.target.value) || event.target.value === "") {
        setUserBioValid(true);
      } else {
        setUserBioValid(false);
      }
    };


    setEditFormBody({
      ...editFormBody,
      [event.target.name]: event.target.value,
    });


    if (isUsernameValid ){
      setFormValid(true)
    } else {
      setFormValid(false)
    }

  }

  const addUserGroup = (event: any, groupId: any) => {
    event.preventDefault();

    setUserGroupIds([
      ...userGroupIds,
      groupId
    ]);

    setFormChanged(true);
  }

  const removeUserGroup = (event: any, groupId: number) => {
    event.preventDefault();
      
    setUserGroupIds([...userGroupIds.filter((group: any) => group !== groupId)]);
    
    setFormChanged(true);
  }

  const validateForm = () => {
    return;
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();

    console.log("Group edit body: ", editFormBody)

    if (isUsernameValid ){

      await handleUserEditSubmit({body: {
        ...editFormBody,
        security: [
          ...userGroupIds
        ]
      }});

    } else {
      console.log("FORM NOT VALID... Won't make it so");
    }
  }

  console.log("User Content: ", userContent);
  console.log("User Groups: ", userGroupIds);
  console.log("User Edit Form: ", editFormBody);

  return (
    <>
      <Form onSubmit={handleFormSubmit} key={userContent.id} className='m-2'>
        <Container className="p-0">
          <Row className="m-0">
            <Col className="col-12 pb-3">
              <Row className="m-0">
                <Col className="col-4 ps-0">
                  <Row className="m-0 k-inputText15">
                    Username
                  </Row>
                  <Row className="m-0">
                    <div className="p-0 k-inputText k-inputText15-light">
                      {userContent.id}
                    </div>
                  </Row>
                </Col>
                <Col className="col-5 ps-0">
                  <Row className="m-0 k-inputText15">
                    Password
                  </Row>
                  <Row className="m-0">
                    <Form.Group className="p-0 " controlId="paneGroupEditForm.Password">
                      <Form.Control className="p-0 k-inputText k-inputText15-light" type="password" placeholder={"Change Password"} name='password' pattern="^\S+$"  onChange={handleInputChange}/>
                    </Form.Group>
                  </Row>
                </Col>
                <Col className="col-3 ps-0">
                  <Row className="m-0 k-inputText15">
                    MFA
                  </Row>
                  <Row className="m-0">
                    <div className="p-0 k-inputText k-inputText15-light">
                      Enforced
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row className="m-0">
                <Col className="col-11 p-0">
                  {isDisplayNameValid ?  null: <small style={{color: "red"}}>Enter a valid Display Name. {"(5 - 25 Alphabets and Numbers only accepted)"}</small>}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="m-0">
            <Col className="col-12">
              <Row className="m-0">
                <Col className="col-4 ps-0">
                  <Row className="m-0 k-inputText15">
                    Role
                  </Row>
                  <Row className="m-0">
                    <Form.Group className="p-0 " controlId="paneUserEditForm.Role">
                      <Form.Select aria-label="Select a role" className="p-0 k-inputSelect k-inputText15-light" name='role' defaultValue={userContent.role} onChange={handleInputChange} >
                        <option value="user" >Analyst</option>
                        <option disabled value="manager">Project Manager</option>
                        <option value="administrator">Admin</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                </Col>
                <Col className="col-5 ps-0">
                  <Row className="m-0 k-inputText15">
                    Last Sign-In
                  </Row>
                  <Row className="m-0">
                    <div className="p-0 k-inputText k-inputText15-light">
                      ---
                    </div>
                  </Row>
                </Col>
                <Col className="col-3 ps-0">
                  <Row className="m-0 k-inputText15">
                    Sign-In State
                  </Row>
                  <Row className="m-0">
                    <div className="p-0 k-inputText k-inputText15-light">
                      { userContent.enabled ? "ENABLED" : "DISABLED" }
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row className="m-0">
                <Col className="col-1"/>
                <Col className="col-11 p-0">
                  {isUserPasswordValid ?   null : <small style={{color: "red"}}>Enter a valid User Password. {"(Alphabets, Numbers, and charcaters[-.,()/] accepted)"}</small>}
                  {/* {isUserPasswordConfirmValid ?   null : <small style={{color: "red"}}>Re-Enter User Password to Confirm. {"(Alphabets, Numbers, and charcaters[-.,()/] accepted)"}</small>} */}
                </Col>
                
              </Row>
            </Col>
          </Row>
          <Row><Col className='ps-4 pe-4'><hr/></Col></Row>
          <Row className="m-0">
            <Col className="col-12">
              <Row className="m-0">
                <Col className="col-12 ps-0">
                  <Row className="m-0 p-0">
                    <Row className="m-0 p-0">
                      <Col className="col-4 ps-0 k-inputText15">
                          Group
                      </Col>
                      <Col className="col-5 ps-1 k-inputText15">
                          Description
                      </Col>
                      <Col className="col-3 k-inputText15">
                          Action
                      </Col>
                    </Row>

                    {userGroupIds && userGroupIds.map((groupId: any, index: number) => (
                    <Row key={`paneUserEditForm.Groups.${index}-${groupId}`} className='m-0 p-0'>
                      <Col className="col-4 k-inputText k-inputText15-light ps-0">
                          {securityGroups.filter((globalGroup: any) => groupId === globalGroup.id)[0].name}
                      </Col>
                      <Col className="col-5 k-inputText k-inputText15-light ps-1">
                        ----
                      </Col>
                      <Col className="col-3">
                        <div style={{ cursor: "pointer"}} onClick={(event: any) => removeUserGroup(event, groupId)}>
                          <span>
                            remove
                          </span>
                        </div>
                      </Col>
                    </Row>))}
                  </Row>

                  <Row>
                    <Col className="col-11 p-0 ">
                      <Dropdown>
                        <Dropdown.Toggle as={SelectGroupDropdownToggle}>
                          <Row className='m-0 pt-1'>
                            <Col className="col-1 pe-0">
                              <img alt="name icon" src={resourceAddIcon} className="pe-1 k-mmh-15 k-pb-2"/>
                            </Col>
                            <Col className="col-11 p-0 k-inputText15 k-ml--10 k-pt-1">
                              Add group
                            </Col>
                          </Row>
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={SelectGroupDropdownMenu} >
                          {securityGroups && securityGroups.map((group: any, index: number) => {
                            return (userGroupIds && userGroupIds.includes(group.id)) ?   
                              null :
                              (<Dropdown.Item className="k-inputText12" key={`paneUserEditForm.Dropdown.${index}`} onClick={(event: any) => addUserGroup(event, group.id)}>{group.name}</Dropdown.Item>)
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="m-0 pb-3">
            <Row className="pe-0">
              <Col className="px-0 col-9 col-auto"></Col>
              <Col className="col p-0 ">
                <div className="pt-2">
                  <Button variant="success" 
                    type="submit"
                    disabled={ !isUsernameValid  || !isDisplayNameValid || !isRoleValid || !isUserBioValid || !isUserPasswordValid || !isFormChanged}
                      size="sm" className="k-btn">
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
            {editUserSubmitError? (<>
              <small style={{color: "red"}}>{editUserSubmitError}</small>
            </>): null}
          </Row>
        </Container>
      </Form>
    </>
  );
}

const ResourceAccountPasswordFormPane: React.FunctionComponent<any> = (props: any) => {
  const [passwordFormBody, setPasswordFormBody] = useState<any>({});
  const [isUsernameValid, setUsernameValid] = useState(true);
  const [isUrlValid, setUrlValid] = useState(true);
  const [isPasswordValid, setPasswordValid] = useState(true);
  // const [updatedAccountPassword, setUpdatedAccountPassword] = useState<any>({})
  const [isHovered, setIsHovered] = useState(false);

  const [isPasswordViewable, setIsPasswordViewable] = useState<boolean>(false);

  // const [isFormValid, setFormValid] = useState(false);
  const [isFormChanged, setFormChanged] = useState(false);
  
  const { 
    accountPassword,
    editAccountPasswordSubmitError,
    handleAccountPasswordEditSubmit,
    // eventKeyID,    
  } = props;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // console.log("vault eventKeyID / Key : ", eventKeyID);
  console.log("Vault Account / Password Edit : ", accountPassword);

  useEffect(() => {
    setFormChanged(false);
    setPasswordFormBody({...accountPassword});
    setUrlValid(true);
    setUsernameValid(true);
    setPasswordValid(true);

    setIsPasswordViewable(false)
    
    // setUpdatedAccountPassword(accountPassword);
  }, [accountPassword]);

  const handleInputChange = (event: any) => {
    // Toggle Form has Changed
    setFormChanged(true);

    console.log("EVENT CHANGE : ",event.target.name, " : ", event.target.value )
    if(event.target.name === "url") {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.VAULT.URL.test(event.target.value)) {
        setUrlValid(true);
      } else {
        setUrlValid(false);
      }
    };

    if(event.target.name === "username") {
      console.log("testing pass word username edit...");
      if (variables.DEFAULT_FORM_VALIDATION.VAULT.USERNAME.test(event.target.value)) {
        setUsernameValid(true);
      } else {
        setUsernameValid(false);
      }
    };

    if(event.target.name === "title") {
      if (variables.DEFAULT_FORM_VALIDATION.VAULT.TITLE.test(event.target.value)) {
        setUsernameValid(true);
      } else {
        setUsernameValid(false);
      }
    };

    if(event.target.name === "password") {
      if (variables.DEFAULT_FORM_VALIDATION.USER.PASSWORD.test(event.target.value)) {
        setPasswordValid(true);
      } else {
        setPasswordValid(false);
      }
    };

    console.log("a/c passowrd form body: ", passwordFormBody);

    setPasswordFormBody({
      ...passwordFormBody,
      [event.target.name]: event.target.value,
    });


  }

  const handleToggleIsPasswordViewable = (event: any) => {
    event.preventDefault();
    setIsPasswordViewable(!isPasswordViewable);
  }

  const validateForm = () => {
    return;
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();
    
    console.log("Password edit body: ", passwordFormBody);

    // setUpdatedAccountPassword({...passwordFormBody});
    
    toast.success(`${passwordFormBody.title} account has been updated successfully`, {
      position: "top-right",
      autoClose: 3500,
      theme: "light",
      });

    setFormChanged(false);

    if (isUsernameValid ){
      await handleAccountPasswordEditSubmit({
        body: {
          ...helpers.filterObjectByRemovingKeys(passwordFormBody, ["password"])
        },
        password: passwordFormBody.password
      });

    } else {
      console.log("FORM NOT VALID... Won't make it so");
    }
  }

  const handleCopyPassword = () => {
    navigator.clipboard.writeText(accountPassword.password)
      .then(() => {
        console.log('Password copied to clipboard');
      })
      .catch((error) => {
        console.error('Failed to copy password to clipboard:', error);
      });
  };

  // console.log("User Content: ", userContent);
  // console.log("User Groups: ", userGroupIds);
  // console.log("User Edit Form: ", editFormBody);
  //https://197.guru/images/icons/google.com

  return (
    <>
      <Form onSubmit={handleFormSubmit} key={accountPassword.secretId}>
        <Row className="ms-0 me-0 mt-3">
          <Col className='col-2'>
          {(() => {
            try {
              let url = accountPassword.url;
              if (!url.startsWith("http://") && !url.startsWith("https://")) {
                url = "https://" + url;
              }
              const urlObject = new URL(url);
              const domain = urlObject.hostname;

              return (
                <img src={`./../images/icons/${domain}`} alt="Icon" className="k-mmh-50 k-br-5" />
              );
            } catch (error) {
              // Handle the error when accountPassword.url is not a valid URL
              console.log("ERROR: ", error);
              return (
                <img src={`./../images/icons/default`} alt="Icon" className="k-mmh-50 k-br-5" />
              );
            }
          })()}
          </Col>
        </Row>
        <Row className="ms-0 me-0">
          <Col className="col-5 pe-0 nowrap password-details-0">Title:</Col>
          <Col className="col-5 nowrap password-details-1">
            <Form.Group className="p-0 " controlId={`paneResourceAccountPasswordEditForm.tittle-${accountPassword.secretId}`}>
              <Form.Control className="p-0 k-inputText k-inputText15" type="text" defaultValue={accountPassword.title}  name='title' onChange={handleInputChange} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="ms-0 me-0">
          <Col className="col-5 pe-0 nowrap password-details-0">Username:</Col>
          <Col className="col-5 nowrap password-details-1">
            <Form.Group className="p-0 " controlId={`paneResourceAccountPasswordEditForm.username-${accountPassword.secretId}`}>
              <Form.Control className="p-0 k-inputText k-inputText15" type="text" defaultValue={accountPassword.username}  name='username' onChange={handleInputChange} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="ms-0 me-0">
          <Col className="col-5 pe-0 nowrap password-details-0">URL:</Col>
          <Col className="col-5 nowrap password-details-1">
            <Form.Group className="p-0 " controlId={`paneResourceAccountPasswordEditForm.linkUrl-${accountPassword.secretId}`}>
              <Form.Control className="p-0 k-inputText k-inputText15" type="text" defaultValue={accountPassword.url}  name='url' onChange={handleInputChange} />
            </Form.Group>
          </Col>
        </Row>


        <Row className="ms-0 me-0">
          <Col className="col-5 pe-0 nowrap password-details-0">Password:</Col>
          <Col className="col-5 nowrap password-details-1">
            <Form.Group className="p-0 " controlId={`paneResourceAccountPasswordEditForm.password-${accountPassword.secretId}`}>
            <Row>
              <Col>
                <div className="form-control-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <Form.Control 
                    className="p-0 k-inputText k-inputText15" 
                    type={isPasswordViewable? "text":"password"} 
                    defaultValue={accountPassword.password}  
                    name='password' 
                    onChange={handleInputChange} 
                  />
                  {isHovered && (
                    <div className="button-container">
                      <Button type="button" onClick={handleToggleIsPasswordViewable} className="p-0 ps-1 pe-1 width-60">
                        {isPasswordViewable ? "Hide" : "Reveal"}
                      </Button>
                      <Button type="button" onClick={handleCopyPassword} className="p-0 ps-1 pe-1 width-60">
                        Copy
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            </Form.Group>
          </Col>
        </Row>

        <Row className="ms-0 me-0">
          {/* <Col className="col-5 pe-0 nowrap password-details-0">OTP:</Col>
          <Col className="col-2 password-details-1">
          </Col>
          <Col className="password-details-1 ps-0 mt-m2">
            <TwoDigitCountdown/>
          </Col> */}
          <TwoDigitCountdown/>
        </Row>

        <Row className="py-3">
          <Row className="pe-0">
            <Col className="px-0 col-9"></Col>
            <Col className="col-3 p-0 ">
              <div className="pt-2">
                <Button variant="success" 
                  type="submit"
                  disabled={ !isUsernameValid  || !isFormChanged}
                    size="sm" className="k-btn">
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </Row>
      </Form>
    </>
  );
}

const ProductAccountPasswordFormPane: React.FunctionComponent<any> = (props: any) => {
  const [passwordFormBody, setPasswordFormBody] = useState<any>({});
  const [isUsernameValid, setUsernameValid] = useState(true);
  const [isUrlValid, setUrlValid] = useState(true);
  const [isPasswordValid, setPasswordValid] = useState(true);
  // const [updatedAccountPassword, setUpdatedAccountPassword] = useState<any>({})

  const [isPasswordViewable, setIsPasswordViewable] = useState<boolean>(false);

  // const [isFormValid, setFormValid] = useState(false);
  const [isFormChanged, setFormChanged] = useState(false);
  
  const { 
    accountPassword,
    editAccountPasswordSubmitError,
    handleAccountPasswordEditSubmit,
    // eventKeyID,    
  } = props;

  // console.log("vault eventKeyID / Key : ", eventKeyID);
  console.log("Vault Account / Password Edit : ", accountPassword);

  useEffect(() => {
    setFormChanged(false);
    setPasswordFormBody({...accountPassword});
    setUrlValid(true);
    setUsernameValid(true);
    setPasswordValid(true);

    setIsPasswordViewable(false)
    
    // setUpdatedAccountPassword(accountPassword);
  }, [accountPassword]);

  const handleInputChange = (event: any) => {
    // Toggle Form has Changed
    setFormChanged(true);

    console.log("EVENT CHANGE : ",event.target.name, " : ", event.target.value )
    if(event.target.name === "url") {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.VAULT.URL.test(event.target.value)) {
        setUrlValid(true);
      } else {
        setUrlValid(false);
      }
    };

    if(event.target.name === "username") {
      console.log("testing pass word username edit...");
      if (variables.DEFAULT_FORM_VALIDATION.VAULT.USERNAME.test(event.target.value)) {
        setUsernameValid(true);
      } else {
        setUsernameValid(false);
      }
    };

    if(event.target.name === "title") {
      if (variables.DEFAULT_FORM_VALIDATION.VAULT.TITLE.test(event.target.value)) {
        setUsernameValid(true);
      } else {
        setUsernameValid(false);
      }
    };

    if(event.target.name === "password") {
      if (variables.DEFAULT_FORM_VALIDATION.USER.PASSWORD.test(event.target.value)) {
        setPasswordValid(true);
      } else {
        setPasswordValid(false);
      }
    };

    console.log("a/c passowrd form body: ", passwordFormBody);

    setPasswordFormBody({
      ...passwordFormBody,
      [event.target.name]: event.target.value,
    });


  }

  const handleToggleIsPasswordViewable = (event: any) => {
    event.preventDefault();
    setIsPasswordViewable(!isPasswordViewable);
  }

  const validateForm = () => {
    return;
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();
    
    console.log("Password edit body: ", passwordFormBody);

    // setUpdatedAccountPassword({...passwordFormBody});
    
    toast.success(`${passwordFormBody.title} account has been updated successfully`, {
      position: "top-right",
      autoClose: 3500,
      theme: "light",
      });

    setFormChanged(false);

    if (isUsernameValid ){
      await handleAccountPasswordEditSubmit({
        body: {
          ...helpers.filterObjectByRemovingKeys(passwordFormBody, ["password"])
        },
        password: passwordFormBody.password
      });

    } else {
      console.log("FORM NOT VALID... Won't make it so");
    }
  }

  // console.log("User Content: ", userContent);
  // console.log("User Groups: ", userGroupIds);
  // console.log("User Edit Form: ", editFormBody);

  return (
    <>
      <Form onSubmit={handleFormSubmit} key={accountPassword.secretId}>
        <Row className="ms-0 me-0">
          <Col className="col-5 pe-0 nowrap password-details-0">Title:</Col>
          <Col className="col-5 nowrap password-details-1">
            <Form.Group className="p-0 " controlId={`paneProfileAccountPasswordEditForm.tittle-${accountPassword.secretId}`}>
              <Form.Control className="p-0 k-inputText k-inputText15" type="text" defaultValue={accountPassword.title}  name='title' onChange={handleInputChange} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="ms-0 me-0">
          <Col className="col-5 pe-0 nowrap password-details-0">Username:</Col>
          <Col className="col-5 nowrap password-details-1">
            <Form.Group className="p-0 " controlId={`paneProfileAccountPasswordEditForm.username-${accountPassword.secretId}`}>
              <Form.Control className="p-0 k-inputText k-inputText15" type="text" defaultValue={accountPassword.username}  name='username' onChange={handleInputChange} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="ms-0 me-0">
          <Col className="col-5 pe-0 nowrap password-details-0">URL:</Col>
          <Col className="col-5 nowrap password-details-1">
            <Form.Group className="p-0 " controlId={`paneProfileAccountPasswordEditForm.linkUrl-${accountPassword.secretId}`}>
              <Form.Control className="p-0 k-inputText k-inputText15" type="text" defaultValue={accountPassword.url}  name='url' onChange={handleInputChange} />
            </Form.Group>
          </Col>
        </Row>


        <Row className="ms-0 me-0">
          <Col className="col-5 pe-0 nowrap password-details-0">Password:</Col>
          <Col className="col-5 nowrap password-details-1">
            <Form.Group className="p-0 " controlId={`paneProfileAccountPasswordEditForm.password-${accountPassword.secretId}`}>
              <Row>
                <Button type="button" onClick={handleToggleIsPasswordViewable}>
                  {isPasswordViewable?
                      "Lock": "View"
                  }
                </Button>
              </Row>
              <Row>
                <Form.Control 
                  className="p-0 k-inputText k-inputText15" 
                  type={isPasswordViewable? "text":"password"} 
                  defaultValue={accountPassword.password}  
                  name='password' 
                  onChange={handleInputChange} 
                />
              </Row>
            </Form.Group>
          </Col>
        </Row>

        <Row className="ms-0 me-0">
          {/* <Col className="col-5 pe-0 nowrap password-details-0">OTP:</Col>
          <Col className="col-2 password-details-1">
          </Col>
          <Col className="password-details-1 ps-0 mt-m2">
            <TwoDigitCountdown/>
          </Col> */}
          <TwoDigitCountdown/>
        </Row>

        <Row className="py-3">
          <Row className="pe-0">
            <Col className="px-0 col-9"></Col>
            <Col className="col-3 p-0 ">
              <div className="pt-2">
                <Button variant="success" 
                  type="submit"
                  disabled={ !isUsernameValid  || !isFormChanged}
                    size="sm" className="k-btn">
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </Row>
        <Row><hr className="mt-1 mb-1 ms-0 me-0"></hr></Row>
      </Form>
    </>
  );
}

const ResourceVaultPasswordCheck: React.FunctionComponent<any> = (props: any) => {
  const [formBody, setFormBody] = useState<any>({});
  const [isPasswordValid, setPasswordValid] = useState(true);

  const [isFormChanged, setFormChanged] = useState(false);
  
  const { 

    vaultPasswordSubmitError,
    handleVaultPasswordSubmit
  } = props;

  useEffect(() => {
    setFormChanged(false);
    setPasswordValid(true);
  }, [vaultPasswordSubmitError]);

  const handleInputChange = (event: any) => {
    // Toggle Form has Changed
    setFormChanged(true);

    //
    // if(event.target.name === "password") {
    //   // event.target.value = event.target.value.split(" ").join("");
    //   // event.target.value = event.target.value.replace(" ", "");
    //   if (variables.DEFAULT_FORM_VALIDATION.GROUP.NAME.test(event.target.value)) {
    //     setPasswordValid(true);
    //   } else {
    //     setPasswordValid(false);
    //   }
    // };

    setFormBody({
      ...formBody,
      [event.target.name]: event.target.value,
    });

  }

  const validateForm = () => {
    return;
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();

    console.log("Vault Password body: ", formBody)

    await handleVaultPasswordSubmit({body: {
      ...formBody,
    }});
  }


  return (
    <>
   
        <Form onSubmit={handleFormSubmit}  className='m-2'>
          <Container className="p-0">
            <Row className="m-0">
              <Col className="col-12">
                <Row className="m-0">
                  <Col className="col-8 ps-0">
                    <Row className="m-0 k-inputText15">
                      Password
                    </Row>
                    <Row className="m-0">
                      <Form.Group className="p-0 " controlId="paneVaultUserPasswordForm.Name">
                        <Form.Control className="p-0 k-inputText k-inputText15-light" type="password" defaultValue="" name='password' onChange={handleInputChange}/>
                      </Form.Group>
                    </Row>
                  </Col>
                  
                </Row>
          
              </Col>
            </Row>
            
            <Row className="m-0 pb-3">
              <Row className="pe-0">
                <Col className="px-0 col-9 col-auto"></Col>
                <Col className="col p-0 ">
                  <div className="pt-2">
                    <Button variant="success" 
                      type="submit"
                      disabled={!isPasswordValid}
                        size="sm" className="k-btn">
                      Verify Passoword
                    </Button>
                  </div>
                </Col>
              </Row>
              {vaultPasswordSubmitError? (<>
                <small style={{color: "red"}}>{vaultPasswordSubmitError}</small>
              </>): null}
            </Row>
          </Container>
        </Form>

    </>
  );
}


export {
  AdminGroupsContentEditFormPane,
  AdminUsersContentEditFormPane,
  ResourceAccountPasswordFormPane,
  ProductAccountPasswordFormPane,
  ResourceVaultPasswordCheck
}