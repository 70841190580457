import axios from 'axios';

import { 
  DEFAULT_CONFIG, 
  DEFAULT_PRODUCT_TYPES 
} from './../variables';




// const getRequestUserToken = (currentUser: any) => {
  
//   const currentUserIdToken = currentUser && currentUser.authToken;

//   return currentUserIdToken
// }

//ID TOKEN

export const getProducts = async(query: any) => {
  
  let path;
  if(query.filter) { 
    path = `/products?filter=${query.filter}`; 
  } else { 
    path = '/products' 
  }

  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"] + path)

  if(apiResponse && apiResponse.status === 200){
    if(query && query.productTypes){
      return apiResponse.data && apiResponse.data.filter((productObject: any)=> {
        return query.productTypes.includes(productObject.product);
      });
    }
    return apiResponse.data;
  }
}

export const getProduct = async(query: any) => {
  
  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/product',{
    params: {
      id: query.body.id
    }
  })

  if(apiResponse && apiResponse.status === 200){
    return apiResponse.data;
  }
}

export const postProduct = async(query: any) => {
  console.log("post Product : query", query);
  try {
    let createProductBody;
    // TO-DO: Check if allowed to add invalid IDs
    let newBrowserBody: any = {
      "region" : query.body.location,
      "identifiers" : {
          "name" : query.body.name,
          // "profile" : query.body.profile,
      },
      "parameters" : {
          "System" : query.body.system
      },
      "vault_keys": query.vaultKeys
    }

    let newCommunicateBody: any = {
      "region" : query.body.location,
      "identifiers" : {
          "name" : query.body.name,
          // "profile" : query.body.profile,
      },
      "parameters" : {
          "System" : query.body.system
      },
      "vault_keys": query.vaultKeys
    }

    let newDesktopBody: any = {
      "region" : query.body.location,
      "identifiers" : {
          "name" : query.body.name,
          // "project" : query.body.project,
          // "profile" : query.body.profile,
          // "security" : query.body.securityGroup
      },
      "parameters" : {
          "Size" : "large",
          "Username" : query.body.productUsername,
          "System" : query.body.system
      },
      "vault_keys": query.vaultKeys
    }


    switch(query.productType){
      case DEFAULT_PRODUCT_TYPES.BROWSER.API_KEY:
        createProductBody = newBrowserBody;
        console.log("CREATING BROWSER...")
        break;
      
      case DEFAULT_PRODUCT_TYPES.DESKTOP.API_KEY:
        createProductBody = newDesktopBody;
        console.log("CREATING DESKTOP...")
        break;
      
      case DEFAULT_PRODUCT_TYPES.VTC.API_KEY:
        createProductBody = newCommunicateBody;
        console.log("CREATING VTC...")
        break;
      
      case DEFAULT_PRODUCT_TYPES.CHAT.API_KEY:
        createProductBody = newCommunicateBody;
        console.log("CREATING CHAT...")
        break;

      default:
        createProductBody = null;
        console.log("CREATING NO PRODUCT...")
        break;
    };


    if (query.body.description != null) {
      createProductBody.identifiers.description = query.body.description;
    }

    if (query.body.profile != null) {
      createProductBody.identifiers.profile = query.body.profile;
    }

    if (query.body.project != null) {
      createProductBody.identifiers.project = query.body.project;
    }

    if (query.body.security != null) {
      createProductBody.identifiers.security = query.body.security;
    }

    console.log("API: POST PRODUCT: PRE-Request: QUERY BODY: ", query.body)
    console.log("API: POST PRODUCT: PRE-Request: PRODUCT BODY: ", createProductBody)
    
    const apiResponse = await axios.put(
      DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/product/aws/${query.productType}`, 
      createProductBody
    );

    if(apiResponse && apiResponse.status === 200 && apiResponse.data &&  apiResponse.data.result === "successful"){
      return {
        result: "Success",
        id: apiResponse.data.id
      };
    } else {
      return {
        ...apiResponse
      }
    }
    
  } catch (error: any) {
    return {
      result: "Failed",
      error: error && error.response && error.response.data && error.response.data.result
    }
  }
}

export const deleteProduct = async(query: any) => {
  
  const apiResponse = await axios.delete(DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/product', {
    // TO-DO: Lambda should be checked to agree with this arrangement
    data: {
      ...query.body,
    }
  })
  

  if(apiResponse && apiResponse.status === 200 ){
    return {
      result: "Success",
    };
  } else {
    return {
      result: "Failed"
    }
  }
}

export const startProduct = async(query: any) => {
  
  const res = await axios.post(
    DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/product/start', 
    query.body);
  return (res);
}

export const hibernateProduct = async(query: any) => {
  
  const res = await axios.post(
    DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/product/hibernate', 
    query.body)
  return (res);
}

export const stopProduct = async(query: any) => {
  
  const res = await axios.post(
    DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/product/stop', 
    query.body)
  return (res);
}

export const postProject = async(query: any) => {
  try {

    let newProjectBody: any = {
      "name" : query.body.name,
      "vault_keys": query.vaultKeys
    }

    if (query.body.description != null) {
      newProjectBody["description"] = query.body.description;
    }
    console.log("API Put project : ", query)
    console.log("API Put project : sorted query  : " , newProjectBody)
    
    const apiResponse = await axios.put(
      DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/project', 
      newProjectBody)

    console.log("API Put project : RES  : " , apiResponse)

    if(apiResponse && apiResponse.status === 200 && apiResponse.data &&  apiResponse.data.result === "successful"){
      return {
        result: "Success",
        id: apiResponse.data.id
      };
    } else {
      return {
        ...apiResponse
      }
    }
    
  } catch (error: any) {
    console.log("API Put project : ERR  : " , error)
    return {
      result: "Failed",
      error: error && error.response && error.response.data && error.response.data.result
    }
  }
}

export const getProjects = async(query: any) => {
  
  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/projects');


  if(apiResponse && apiResponse.status === 200){
    console.log("getProjects : query : ", query)
    console.log("getProjects : response : ", apiResponse)

    return apiResponse.data;
  }
}

export const getProject = async(query: any) => {
  
  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/project',{
    params: {
      id: query.body.id
    }
  })

  console.log("getting project response : ", apiResponse)


  if(apiResponse && apiResponse.status === 200){
    return apiResponse.data;
  }
}

export const deleteProject = async(query: any) => {
  const apiResponse = await axios.delete(DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/project', {
    data: {
      ...query.body,
    }
  })


  if(apiResponse && apiResponse.status === 200 ){
    return {
      result: "Success",
    };
  } else {
    return {
      result: "Failed"
    }
  }

}

export const postProfile = async(query: any) => {
  try {
    console.log("query parameter recieved by postProfile is ", query)

    let newProfileBody: any = {
      "name" : query.body.name,
      "vault_keys": query.vaultKeys,
    }

    let allowedKeys = [
      "ttl",
      "security",
      "project",
      "description",
      "dob",
      "gender",
      "ethnicity",
      "location",
      "nationality",
      "mobile",
      "email"
    ]

    for (const allowedKey of allowedKeys) {
      if (query.body[allowedKey] != null ) {
        newProfileBody[allowedKey] = query.body[allowedKey];
      }
    }

    console.log("API Put profile : ", query)
    console.log("API Put profile : sorted query  : " , newProfileBody)

    const apiResponse = await axios.put(
      DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/profile', 
      newProfileBody,
    );

    console.log("API Put profile : RES  : " , apiResponse)

    if(apiResponse && apiResponse.status === 200 && apiResponse.data &&  apiResponse.data.result === "successful"){
      return {
        result: "Success",
        id: apiResponse.data.id
      };
    } else {
      return {
        ...apiResponse
      }
    }
    
  } catch (error: any) {
    console.log("API Put profile : ERR  : " , error)
    return {
      result: "Failed",
      error: error && error.response && error.response.data && error.response.data.result
    }
  }
}

export const getProfiles = async(query: any) => {
  let path;
  if(query.filter) { 
    path = `/profiles?filter=${query.filter}`; 
  } else { 
    path = '/profiles' 
  }
  
  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"] + path);
  console.log("getProfiles : response : ", apiResponse)

  if(apiResponse && apiResponse.status === 200){
    console.log("getProfiles : query : ", query)
    console.log("getProfiles : response : ", apiResponse)

    return apiResponse.data;
  }
}

export const getProfile = async(query: any) => {
  
  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/profile',{
    params: {
      id: query.body.id
    }
  })

  console.log("getting profile response : ", apiResponse)


  if(apiResponse && apiResponse.status === 200){
    return apiResponse.data;
  }
}

export const deleteProfile = async(query: any) => {
  const apiResponse = await axios.delete(DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/profile', {
    data: {
      ...query.body,
    }
  })


  if(apiResponse && apiResponse.status === 200 ){
    return {
      result: "Success",
    };
  } else {
    return {
      result: "Failed"
    }
  }

}

export const postUser = async(query: any) => {
  try {
    let newUserBody: any = {
      "username": query.body.username,
      "password": query.body.password,
      // "password_confirm": query.body.passwordConfirm,
      "role": query.body.role,
      // "force_change_password" : true,
      "limits" : {
        "vm" : 5,
        "browser" : 5,
        "vpn" : 5,
        "vtc" : 5,
        "chat" : 5,
        "profile" : 5,
        "project" : 5
      }
    }

    // if (query.body.bio != null) {
    //   newUserBody.bio = query.body.bio;
    // }
    
    const apiResponse = await axios.put(
      DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/user', 
      newUserBody, 
    )

    console.log("POST USERS : ", apiResponse)

    if(apiResponse && apiResponse.status === 200 && apiResponse.data &&  apiResponse.data.result === "successful"){
      return {
        result: "Success",
        id: apiResponse.data.id
      };
    } else {
      return {
        ...apiResponse
      }
    }
    
  } catch (error: any) {
    return {
      result: "Failed",
      error: error && error.response && error.response.data && error.response.data.result
    }
  }
}

export const editUser = async(query: any) => {
  try {

    console.log("Edit User : query: ", query);
    let editUserBody: any = {
      "username": query.body.id,
      // "password": query.body.password,
      // // "password_confirm": query.body.passwordConfirm,
      "role": query.body.role,
      "security": [
        ...query.body.security
      ]
      // "force_change_password" : true,
    }

    if (query.body.password != null) {
      editUserBody.password = query.body.password;
    }

    console.log("Edit User : editbody: ", editUserBody);

    
    const apiResponse = await axios.post(
      DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/user', 
      editUserBody, 
    )

    console.log("Edit User : response : ", apiResponse)

    if(apiResponse && apiResponse.status === 200 && apiResponse.data &&  apiResponse.data.result === "successful"){
      return {
        result: "Success",
        id: apiResponse.data.id
      };
    } else {
      return {
        ...apiResponse
      }
    }
    
  } catch (error: any) {
    return {
      result: "Failed",
      error: error && error.response && error.response.data && error.response.data.result
    }
  }
}

export const getUsers = async(query: any) => {
  
  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/users')
  console.log("getUsers : query : ", query)
  console.log("getUsers : response : ", apiResponse)
  if(apiResponse && apiResponse.status === 200){
    return apiResponse.data;
  }
}

export const getUser = async(query: any) => {
  
  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/user',{
    params: {
      id: query.body.id
    }
  })

  console.log("getting USER response : ", apiResponse)
  console.log("getUser : query : ", query)

  if(apiResponse && apiResponse.status === 200){
    return apiResponse.data;
  }
}

export const deleteUser = async(query: any) => {
  const apiResponse = await axios.delete(DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/user', {
    data: {
      ...query.body,
    }
  })

  console.log("delete Users : query : ", query)
  console.log("delete Users : response : ", apiResponse)

  if(apiResponse && apiResponse.status === 200 ){
    return {
      result: "Success",
    };
  } else {
    return {
      result: "Failed"
    }
  }

}

export const postSecurityGroup = async(query: any) => {
  try {

    let newSecurityGroupBody: any = {
      "name": query.body.name,
      // "ttl": query.body.ttl,
    }

    if (query.body.description != null) {
      newSecurityGroupBody.description = query.body.description;
    }

    if (query.body.users != null) {
      newSecurityGroupBody.users =[ ...query.body.users];
    }
    
    const apiResponse = await axios.put(
      DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/security', 
      newSecurityGroupBody
    )

    console.log("post Group : query : ", query)
    console.log("post group : response : ", apiResponse)

    if(apiResponse && apiResponse.status === 200 && apiResponse.data &&  apiResponse.data.result === "successful"){
      return {
        result: "Success",
        id: apiResponse.data.id
      };
    } else {
      return {
        ...apiResponse
      }
    }
    
  } catch (error: any) {
    return {
      result: "Failed",
      error: error && error.response && error.response.data && error.response.data.result
    }
  }
}

export const editSecurityGroup = async(query: any) => {
  try {

    let updatedSecurityGroupBody: any = {
      id : query.body.id,
      name : query.body.name,
      users : [
        ...query.body && query.body.users
      ]
    }

    console.log("Edit Group : query body: ", updatedSecurityGroupBody)

    if (query.body.description != null) {
      updatedSecurityGroupBody.description = query.body.description;
    }
    
    const apiResponse = await axios.post(
      DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/security', 
      updatedSecurityGroupBody, 
    )

    console.log("Edit Group : query : ", query)
    console.log("Edit group : response : ", apiResponse)

    if(apiResponse && apiResponse.status === 200 && apiResponse.data &&  apiResponse.data.result === "successful"){
      return {
        result: "Success",
        id: apiResponse.data.id
      };
    } else {
      return {
        ...apiResponse
      }
    }
    
  } catch (error: any) {
    return {
      result: "Failed",
      error: error && error.response && error.response.data && error.response.data.result
    }
  }
}

export const getSecurityGroups = async(query: any) => {
  
  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/securities')
  /*
  , {
    headers: {
      'Authorization': `Bearer ${getRequestUserToken(query.user)}`
    }
  })
  */
  //console.log("get Groups : query : ", query)
  //console.log("get groups : response : ", apiResponse)

  if(apiResponse && apiResponse.status === 200){
    return apiResponse.data;
  }
}

export const getSecurityGroup = async(query: any) => {
  
  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/security',{
    params: {
      id: query.body.id
    }
  })

  console.log("get Group : query : ", query)
  console.log("get group : response : ", apiResponse)


  if(apiResponse && apiResponse.status === 200){
    return apiResponse.data;
  }
}

export const deleteSecurityGroup = async(query: any) => {
  const apiResponse = await axios.delete(DEFAULT_CONFIG["LAMBDA_API_URL"]  + '/security', {
    data: {
      ...query.body,
    }
  })

  console.log("delete Groups : query : ", query)
  console.log("delete groups : response : ", apiResponse)

  if(apiResponse && apiResponse.status === 200 ){
    return {
      result: "Success",
    };
  } else {
    return {
      result: "Failed"
    }
  }

}



/**
 * Resource Security Group: PUT
 */
export const putResourceSecurityGroup = async(query: any) => {
  try {
    const apiResponse = await axios.put(
      DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/${query.resourceType}/security`, 
      {
        id : query.resourceId,
        security : query.securityGroupID
      }
    );

    if(apiResponse && apiResponse.status === 200 && apiResponse.data &&  apiResponse.data.result === "successful"){
      return {
        result: "Success",
        res: apiResponse.data
      };
    } else {
      return {
        result: "Failure",
        res: apiResponse
      }
    }
    
  } catch (error: any) {
    return {
      result: "Failed",
      error: error && error.response && error.response.data && error.response.data.result
    }
  }
  
}

/**
 * Resource Profile: PUT
 * To Be Migrated
 */
export const putResourceProfile = async(query: any) => {
  try {
    console.log("SET PROFILE : ", query);

    const apiResponse = await axios.post(
      DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/product`, 
      {
        "id" : query.resourceId,
        "changes": {
          "profile" : query.profileID
        } 
      }
    );

    if(apiResponse) {
      if (apiResponse.status === 200 && apiResponse.data &&  apiResponse.data.result === "successful"){
        return {
          result: "Success",
          res: apiResponse.data
        };
      } else {
        return {
          result: "Failure",
          res: apiResponse
        }
      }
    } else {
      return {
        result: "Failure",
        res: {state: "ERROR No API Response"}
      }
    }
    
  } catch (error: any) {
    return {
      result: "Failed",
      error: error && error.response && error.response.data && error.response.data.result
    }
  }
  
}

//Product Profiles
export const putProductProfile = async(query: any) => {
  try {
    console.log("SET PROFILE : ", query);

    const apiResponse = await axios.post(
      DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/product`, 
      {
        "id" : query.id,
        "changes": {
          "profile" : query.profileID
        } 
      }
    );

    if(apiResponse) {
      if (apiResponse.status === 200 && apiResponse.data &&  apiResponse.data.result === "successful"){
        return {
          result: "Success",
          res: apiResponse.data
        };
      } else {
        return {
          result: "Failure",
          res: apiResponse
        }
      }
    } else {
      return {
        result: "Failure",
        res: {state: "ERROR No API Response"}
      }
    }
    
  } catch (error: any) {
    return {
      result: "Failed",
      error: error && error.response && error.response.data && error.response.data.result
    }
  }
  
}


/**
 * Resource Notes
 */
export const getResourceNotes = async(query: any) => {
  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/${query.resourceType}/${query.resourceId}/notes`)

  console.log("getting notes response : ", apiResponse)

  if(apiResponse && apiResponse.status === 200){
    return apiResponse.data
  } else {
    return {
      ...apiResponse
    }
  }
}


export const getResourceNote = async(query: any) => {

  console.log("LOG NOTE get note query : ", query)
  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/${query.resourceType}/${query.resourceId}/notes/${query.noteId}`)

  console.log("LOG NOTE getting notes response : ", apiResponse)

  if(apiResponse && apiResponse.status === 200){
    return apiResponse.data
  } else {
    return null
  }
}


export const postResourceNote = async(query: any) => {
  try {
    let instance = axios.create();
    instance.defaults.headers.common['x-k-note-title'] = query.title;
    instance.defaults.headers.common['x-k-note-headline'] = query.headline;

    const apiResponse = await instance.put(
      DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/${query.resourceType}/${query.resourceId}/notes`,
    )
    
    if(apiResponse && apiResponse.status === 200 && apiResponse.data &&  apiResponse.data.result === "successful"){
      return {
        result: "Success",
        id: apiResponse.data.id
      };
    } else {
      return {
        result: "Failed",
        res: apiResponse
      }
    }
  } catch (error: any) {
    return {
      result: "Failed",
      error: error && error.response && error.response.data && error.response.data.result
    }
  }
}


export const putResourceNote = async(query: any) => {
  try {

    //const testBody = '<h1> Hello World Baby!!! <h1>';
    console.log("LOG NOTE putResourceNote: ", query);
    let instance = axios.create();
    instance.defaults.headers.common['x-k-note-title'] = query.title;
    instance.defaults.headers.common['x-k-note-headline'] = query.headline;

    const apiResponse = await instance.put(
      DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/${query.resourceType}/${query.resourceId}/notes/${query.noteId}`,
      query.body
    )
    
    if(apiResponse && apiResponse.status === 200 && apiResponse.data &&  apiResponse.data.result === "successful"){
      return {
        result: "Success",
        id: apiResponse.data.id
      };
    } else {
      return {
        ...apiResponse
      }
    }
    
  } catch (error: any) {
    console.log("API: NOTE ADD ERROR", error);
    console.log("API: NOTE ADD ERROR RES", error.response);
    return {
      result: "Failed",
      error: error && error.response && error.response.data && error.response.data.result
    }
  }
}


export const deleteResourceNote = async(query: any) => {
  const apiResponse = await axios.delete(DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/${query.resourceType}/${query.resourceId}/notes/${query.noteId}`)

  console.log("deleting note response : ", apiResponse)

  if(apiResponse && apiResponse.status === 200){
    return {
      result: "Success",
      data: apiResponse.data
    };
  } else {
    return {
      ...apiResponse
    }
  }
}


/**
 * Resource Vault
 */
export const getResourceVault = async(query: any) => {
  
  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/${query.resourceType}/${query.resourceId}/vault`)

  console.log("getting passwords response : ", apiResponse)


  if(apiResponse && apiResponse.status === 200){
    return {
      result: "Success",
      data: apiResponse.data
    };
  } else {
    return {
      ...apiResponse
    }
  }
}


export const postResourceVaultPassword = async(query: any) => {
  console.log("Creating passwords query : ", query);
  const apiResponse = await axios.put(DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/${query.resourceType}/${query.resourceId}/vault`)
  //api/product/k-xx/vault

  console.log("Creating passwords response : ", apiResponse)

  if(apiResponse && apiResponse.status === 200){
    return {
      result: "Success",
      data: apiResponse.data
    };
  } else {
    return {
      ...apiResponse
    }
  }
}


export const patchResourceVaultPassword = async(query: any) => {
  console.log("Updating passwords query : ", query);
  const apiResponse = await axios.patch(DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/${query.resourceType}/${query.resourceId}/vault`,
    query.body
  )
  //api/product/k-xx/vault

  console.log("Updating passwords response : ", apiResponse)

  if(apiResponse && apiResponse.status === 200){
    return {
      result: "Success",
      data: apiResponse.data
    };
  } else {
    return {
      ...apiResponse
    }
  }
}


export const deleteResourceVaultPassword = async(query: any) => {
  
  const apiResponse = await axios.delete(DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/${query.resourceType}/${query.resourceId}/vault/${query.secretId}`)

  console.log("Deleting Secret response : ", apiResponse)

  if(apiResponse && apiResponse.status === 200){
    return {
      result: "Success",
      data: apiResponse.data
    };
  } else {
    return {
      ...apiResponse
    }
  }

}


/**
 * Resource Favourite
 * Toggle: Add/Remove
 */
export const toggleResourceFavourite = async(query: any) => {
  console.log("API: RESOURCE FAVORITE: QUERY: ", query);

  const apiResponse = await axios({
    method: query.toggleType,
    url: DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/${query.resourceType}/${query.resourceId}/favorite`
  });
  //api/product/k-xx/vault

  console.log("Creating passwords response : ", apiResponse)

  if(apiResponse && apiResponse.status === 200){
    return {
      result: "Success",
      data: apiResponse.data
    };
  } else {
    return {
      ...apiResponse
    }
  }
}


/**
 * User: Vault Keys
 */

export const patchUserVaultKeys = async(query: any) => {
  console.log("Patching User Vault Keys query : ", query);
  const apiResponse = await axios.patch(DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/${query.resourceType}/${query.resourceId}/vault/keys`,
    query.body
  )

  console.log("Patch user vault keys response : ", apiResponse)

  if(apiResponse && apiResponse.status === 200){
    return {
      result: "Success",
      data: apiResponse.data
    };
  } else {
    return {
      ...apiResponse
    }
  }
}

export const getUsersWithoutKeys = async(query: any) => {

  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/${query.resourceType}/${query.resourceId}/vault/users_without_keys`, 
  {
    validateStatus: () => true 
  });

  console.log("getUsersWithoutKeys response : ", apiResponse)

  if(apiResponse && apiResponse.status === 200){
    return {
      result: "Success",
      data: apiResponse.data
    };
  } else {
    return {
      ...apiResponse
    }
  }
}

export const getUserKeys = async(query: any) => {
  const apiResponse = await axios.get(
    DEFAULT_CONFIG["LAMBDA_API_URL"] + `/user/${query.username}/keys/`, 
    {
      validateStatus: () => true 
    }
  );

  console.log("Getting keys for user: ", apiResponse)

  if(apiResponse && apiResponse.status === 200){
    return {
      hasKeys: true,
      hasError: false,
      data: apiResponse.data
    };
  } else if (apiResponse && apiResponse.status === 404) {
    return {
      hasKeys: false,
      hasError: false,
      data: apiResponse.data
    }
  } else {
    return {
      hasKeys: false,
      hasError: true,
      ...apiResponse
    }
  }

}

export const putUserKeys = async (query: any) => {
  const apiResponse = await axios.put(DEFAULT_CONFIG["LAMBDA_API_URL"] + `/user/${query.username}/keys/`, query.keys)

  console.log("Putting keys for user ", apiResponse)

  if(apiResponse && apiResponse.status === 200){
    return {
      result: "Success",
      data: apiResponse.data
    };
  } else {
    return {
      ...apiResponse
    }
  }

}


//******************************* */




/**
 * LOGS: SYSTEM
 */
export const getResourceSystemLogs = async(query: any) => {
  
  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/${query.resourceType}/${query.resourceId}/logs/system`)

  console.log("getting product log system response : ", apiResponse)


  if(apiResponse && apiResponse.status === 200){
    return {
      result: "Success",
      data: apiResponse.data
    };
  } else {
    return {
      result: "Failure",
      data: apiResponse.data
    }
  }
}


/**
 * LOGS: PRINTER
 */
export const getResourcePrinterLogs = async(query: any) => {

  console.log(`API ${query && query.resourceType} PrinterLogs Query : `, query);
  if ('resourceId' in query) {
    //build up url
    let url = `${DEFAULT_CONFIG["LAMBDA_API_URL"]}/${query.resourceType}/${query.resourceId}/logs/printer`;

    let params = new URLSearchParams();
    // Add start parameter
    if  (query.start !== undefined &&  /^\d+$/.test(query.start))  {
      params.append('start', query.start);
    }

    // Add stop parameter
    if  (query.end !== undefined &&  /^\d+$/.test(query.end))  {
      params.append('end', query.end);
    }

    // Add max parameter
    if  (query.max !== undefined &&  /^\d+$/.test(query.max))  {
      params.append('max', query.max);
    }

    // Add token parameter
    if  (query.token !== undefined &&  /^[a-zA-Z0-9]+$/.test(query.token))  {
      params.append('token', query.token);
    }

    console.log("OPTIONS: ", params.toString());

    //Add any parameters that have been passed
    let castParams = (params as any);
    if (castParams.length > 0) {
      url += `?${params.toString()}`
    }
    
    // Send Request
    let apiResponse;
    try {
      apiResponse = await axios.get(url)
    } catch (err) {
      return {result: 'Failure', data: err}
    }

    if(apiResponse && apiResponse.status === 200){
      if (apiResponse.data !== undefined && apiResponse.data.printer !== undefined ) {
        return {
          result: "Success",
          status: apiResponse.status,
          data: apiResponse.data
        };
      } else {
        return {
          result: "Success",
          status: apiResponse.status,
          data: null
        };
      }
    }else {
      return {
        result: "Failure",
        status: apiResponse.status,
        data: null
      };
    }
  } else {
    return {
      result: "Failure",
      status: '000',
      data: null
    };
  }
};
  

/**
 * LOGS: PLATFORM
 */
export const getResourcePlatformLogs = async(query: any) => {
  
  const apiResponse = await axios.get(DEFAULT_CONFIG["LAMBDA_API_URL"]  + `/${query.resourceType}/${query.resourceId}/logs/platform`)

  console.log("getting project log platform response : ", apiResponse)


  if(apiResponse && apiResponse.status === 200){
    return {
      result: "Success",
      data: apiResponse.data
    };
  } else {
    return {
      result: "Failure",
      data: apiResponse.data
    }
  }
}




