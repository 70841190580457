/**
 * STUBS:
 *  - Adds Stubs for testing and local development
 */

export const passwords = [
  {
    title: "Twitter Research", 
    username: "obbby@galacticflight.com",
    url: "https//twitter.com",
    password: "**************",
    otp: "195-921"
  },
  {
    title: "Facebook Home", 
    username: "tabs@subzero.com",
    url: "https//facebook.com",
    password: "**************",
    otp: "485-573"
  },
  {
    title: "TikTok Social", 
    username: "bunker-one@socialflow.com",
    url: "https//tiktok.com",
    password: "**************",
    otp: "211-877"
  },
  {
    title: "YouTube Premium", 
    username: "bunker-one@socialflow.com",
    url: "https//youtube.com",
    password: "**************",
    otp: "891-761"
  },
]


export const groups = [{
  description: "testing group 1",
  id: "k-sg-test-1",
  name: "testgroup1",
  user: "kuroadmin",
  users: [
    "kuroadmin","k-test-user"
  ]
},{
  description: "testing group 2",
  id: "u-sg-test-2",
  name: "testgroup2",
  user: "kuroadmin",
  users: []
},{
  description: "testing group 3",
  id: "g-sg-test-3",
  name: "testgroup3",
  user: "kuroadmin",
  users: [
    "kuroadmin"
  ]
}];


export const users = [{
  enabled: false,
  id: "k-test-user",
  role: "administrator",
  security: ["u-sg-test-2"]
},{
  enabled: true,
  id: "kuroadmin",
  role: "administrator",
  security: ["u-sg-test-2"]
},{
  enabled: false,
  id: "k-test-user-not-in",
  role: "administrator",
  security: ["g-sg-test-3"]
},{
  enabled: true,
  id: "k-test-user-not-in2",
  role: "administrator",
  security: ["k-sg-test-1"]
}]


export const browsers: any = [{
  product: "browser",
  user: "kuroadmin",
  provider: "aws",
  region: "eu-west-2",
  url: "https://k-x3ucpaeyvx5kbo.vm.darkcloud.me",
  id: "k-x3ucpaeyvx5kbo",
  name: "Browser TEST 1",
  state: "building",
  description: "Browser 1 as NOT Favorite",
  isFavouriteFromApi: false
},{
  product: "browser",
  user: "kuroadmin",
  provider: "aws",
  region: "eu-west-2",
  url: "https://k-x1122334488.vm.darkcloud.me",
  id: "k-x1122334488",
  name: "BROWSER Test 2",
  state: "running",
  description: "browser 2 a favorite",
  isFavouriteFromApi: true
}]


export const desktops: any = [{
  product: "vm",
  user: "kuroadmin",
  provider: "aws",
  region: "eu-west-2",
  url: "https://k-x3ucpaeyvx5kbo.vm.darkcloud.me",
  id: "k-x3ucpaeyvx5kbo",
  name: "VM TEST 1",
  state: "stopped",
  description: "desktop 1 as NOT Favorite",
  isFavouriteFromApi: false
},{
  product: "vm",
  user: "kuroadmin",
  provider: "aws",
  region: "eu-west-2",
  url: "https://k-x1122334488.vm.darkcloud.me",
  id: "k-x1122334488",
  name: "VM Test 2",
  state: "running",
  description: "desktop 2 a favorite",
  isFavouriteFromApi: true
}]


export const communicates: any = [{
  product: "vm",
  user: "kuroadmin",
  provider: "aws",
  region: "eu-west-2",
  url: "https://k-x3ucpaeyvx5kbo.vm.darkcloud.me",
  id: "k-x3ucpaeyvx5kbo",
  name: "VM TEST 1",
  state: "stopped",
  description: "comms 1 as NOT Favorite",
  isFavouriteFromApi: false
},{
  product: "vm",
  user: "kuroadmin",
  provider: "aws",
  region: "eu-west-2",
  url: "https://k-x1122334488.vm.darkcloud.me",
  id: "k-x1122334488",
  name: "VM Test 2",
  state: "running",
  description: "comms 2 a favorite",
  isFavouriteFromApi: true
}]


export const generalStats = [
  {
    header: "Projects", 
    description: "Active projects", 
    numberCount: 0
  },
  {
    header: "Profiles", 
    description: "Active profiles", 
    numberCount: 0
  },
  {
    header: "Desktops", 
    description: "Active desktops", 
    numberCount: 400
  },
  {
    header: "Browsers", 
    description: "Active browsers", 
    numberCount: 0
  },
  {
    header: "Messaging", 
    description: "Messaging Services", 
    numberCount: 0
  },

]



