import React from 'react';
import { Col, Row, Tab, Nav } from 'react-bootstrap';

import {
  infoIcon,
} from "./../../assets/images";

import { 
  AdminUsersContentTab,
  AdminGroupsContentTab,
  AdminNotificationsContentTab, 

} from "./../";

import { 
  variables, 

} from "./../../utilities";





const ProductContentPane: React.FunctionComponent<any> = (props:any) => {
  const { productDetails, mediaControlHandlers, productType } = props;
  const lowercaseProductType = productType && productType.toLowerCase();


  return (
    <>
      {(() => {
        switch (productDetails.state.toUpperCase()) {
          case variables.DEFAULT_PRODUCT_STATES.RUNNING:
            return (
              <>
                {/* <iframe className="k-iFrame d-flex align-self-stretch" 
                  allow="clipboard-read; clipboard-write;camera; microphone;" id={`desktop-iframe-${productDetails.id}`} 
                  src={productDetails.url} title={productDetails.id} 
                  onMouseOver={mediaControlHandlers.handleIframeMouseOver}>
                </iframe> */}
                <Row className="justify-content-md-center">
                  <Col md="auto" className="k-iFrameCenter">
                    <Row>
                      <Col className="col-3 pt-2">
                        <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg animate-flicker k-statusImg"/>
                      </Col>
                      <Col className="ps-0">
                        <h1>Running</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <a target="_blank" href={productDetails.url} rel="noreferrer"><h4>Your {lowercaseProductType} is running here!</h4></a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </> 
            );
          case variables.DEFAULT_PRODUCT_STATES.BUILDING:
            return (
              <>
                <Row className="justify-content-md-center">
                  <Col md="auto" className="k-iFrameCenter">
                    <Row>
                      <Col className="col-3 pt-2">
                        <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg animate-flicker k-statusImg"/>
                      </Col>
                      <Col className="ps-0">
                        <h1>Building</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4>Your {lowercaseProductType} is building!</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );

          case variables.DEFAULT_PRODUCT_STATES.STOPPING:
            return (
              <>
                <Row className="justify-content-md-center">
                  <Col md="auto" className="k-iFrameCenter">
                    <Row>
                      <Col className="col-3 pt-2">
                        <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg animate-flicker k-statusImg"/>
                      </Col>
                      <Col className="ps-0">
                        <h1>Stopping</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4>Your {lowercaseProductType} is stopping!</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );

          case variables.DEFAULT_PRODUCT_STATES.STOPPED:
            return (
              <>
                <Row className="justify-content-md-center">
                  <Col md="auto" className="k-iFrameCenter">
                    <Row>
                      <Col className="col-3 pt-2">
                        <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg k-statusImg"/>
                      </Col>
                      <Col className="ps-0">
                        <h1>Stopped</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      <h4>Your {lowercaseProductType} is not running, <br/>click the start button to resume</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );

          case variables.DEFAULT_PRODUCT_STATES.STARTING:
            return (
              <>
                <Row className="justify-content-md-center">
                  <Col md="auto" className="k-iFrameCenter">
                    <Row>
                      <Col className="col-3 pt-2">
                        <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg animate-flicker k-statusImg"/>
                      </Col>
                      <Col className="ps-0">
                        <h1>Starting</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4>Your {lowercaseProductType} is starting!</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );

          case variables.DEFAULT_PRODUCT_STATES.HIBERNATING:
            return (
              <>
                <Row className="justify-content-md-center">
                  <Col md="auto" className="k-iFrameCenter">
                    <Row>
                      <Col className="col-3 pt-2">
                        <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg animate-flicker k-statusImg"/>
                      </Col>
                      <Col className="ps-0">
                        <h1>Hibernating</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      <h4>Your {lowercaseProductType} is hibernating!</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );

          case variables.DEFAULT_PRODUCT_STATES.HIBERNATION:
            return (
              <>
                <Row className="justify-content-md-center">
                  <Col md="auto" className="k-iFrameCenter">
                    <Row>
                      <Col className="col-3 pt-2">
                        <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg k-statusImg"/>
                      </Col>
                      <Col className="ps-0">
                        <h1>Hibernation</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4>Your {lowercaseProductType} is in hibernation, <br/>click the start button to resume</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );

          case variables.DEFAULT_PRODUCT_STATES.ERROR:
            return (
              <>
                <Row className="justify-content-md-center">
                  <Col md="auto" className="k-iFrameCenter">
                    <Row>
                      <Col className="col-3 pt-1">
                        <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg animate-flicker k-statusImg"/>
                      </Col>
                      <Col className="ps-0">
                        <h1>Error</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4>Humm ... Looks like something has gone wrong ... hold tight we are fixing it</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );

          default:
            return null
        }
      })()}
    </>
  );
};

const AdminContentPane: React.FunctionComponent<any> = (props: any) => {
  const { 
    users,
    groups,
    notifications,
    selectedObject,
    mediaControlHandlers,
    editUserSubmitError,
    editGroupSubmitError,
    handleAdminContentPaneTabsToggle, 
    adminContentPaneTabsActiveKey, 
    handleShowAddUserModal,
    handleShowAddGroupModal,
    handleUserEditSubmit,
    handleGroupEditSubmit,
    handleAdminSelectObject
  } = props;


  return(
    <>
      <Tab.Container id="profile-content-pane" 
        defaultActiveKey="admin.Users"
        activeKey={adminContentPaneTabsActiveKey}
        onSelect={handleAdminContentPaneTabsToggle}
      >
        <Row>
          <Row>
            <Nav className="pb-1 k-tabList">
              <Nav.Item className="">
                <Nav.Link eventKey="admin.Users" className="p-0 k-tabNav">
                  User
                  <span className='k-tabSubHeader'> Admin</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link eventKey="admin.Groups" className="p-0 k-tabNav">
                  Group
                  <span className='k-tabSubHeader'> Admin</span>
                </Nav.Link>
              </Nav.Item>
              {/*<Nav.Item className="">
                <Nav.Link eventKey="admin.Notifications" className="p-0 k-tabNav">
                  Notifications
                  <span className='k-tabSubHeader'> Display</span>
                </Nav.Link>
              </Nav.Item>*/}
            </Nav>
          </Row>
          <Row className="mt-4 pe-0">
            <Tab.Content >
              <Tab.Pane eventKey="admin.Users">
                <AdminUsersContentTab
                  handleShowAddUserModal={handleShowAddUserModal}
                  users={users}
                  groups={groups}
                  selectedUser={selectedObject}
                  editUserSubmitError={editUserSubmitError}
                  handleUserEditSubmit={handleUserEditSubmit}
                  handleAdminSelectObject={handleAdminSelectObject}
                  mediaControlHandlers={mediaControlHandlers}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="admin.Groups">
                <AdminGroupsContentTab
                  handleShowAddGroupModal={handleShowAddGroupModal}
                  users={users}
                  groups={groups}
                  selectedGroup={selectedObject}
                  editGroupSubmitError={editGroupSubmitError}
                  handleGroupEditSubmit={handleGroupEditSubmit}
                  handleAdminSelectObject={handleAdminSelectObject}
                  mediaControlHandlers={mediaControlHandlers}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="admin.Notifications">
                <AdminNotificationsContentTab
                  notifications={notifications}
                  selectedNotification={selectedObject}
                  handleAdminSelectObject={handleAdminSelectObject}
                />
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Row>
      </Tab.Container>
    </>
  )
}





export {
  ProductContentPane,
  AdminContentPane,



};