
import React from 'react';
import { Col, Row, Dropdown } from 'react-bootstrap';

import {
  hideContentBtn,
  retractContentBtn,
  expandContentBtn,
  // openMenuDownBtn,
  infoIcon,
} from "./../../assets/images";


import { 
  ProductControlPanel, 
  ProductContentPane,
  // ProductToolsPanel,
  SelectGroupDropdownMenu,
  SelectGroupDropdownToggle,
  DesktopLogoImage,
} from './../';


import { 
  helpers,
} from './../../utilities';




const Product: React.FunctionComponent<any> = (props:any) => {
  const {
    desktopDetails, 
    mediaControlHandlers, 
    desktopStatus, 
    productType,
    // desktopNotes, 
    groups, 
    desktopVault,
    desktopVaultPasswordSubmitError,
    desktopVaultSecretEditSubmitError,
    decryptedVaultKey,
    preSelectedSecretId,
    // productNoteHandlers,
    // productVaultHandlers,
    toggleHandlers,
  } = props;

  const lowercaseProductType = productType && productType.toLowerCase();

  console.log("Desktop: Vault :", desktopVault);
  console.log("Desktop: Decrypted Vault Key", decryptedVaultKey);
  console.log("Desktop: Preselected Id", preSelectedSecretId);
  console.log("Desktop: Password Submit Error", desktopVaultPasswordSubmitError);
  console.log("Desktop: Secret Edit Submit Errors", desktopVaultSecretEditSubmitError);

  return (
    <>
      <Row className="ps-3">
        <Col className="col-6">
          <Row className="pb-3">
            <Col className="col-1 px-0">
              <DesktopLogoImage
                system={desktopDetails.system}
                classOverride="width-90-PC"
              />
            </Col>
            <Col className="col-11 ps-3">
              <Row>
                <span className="k-detailHeader p-0">{desktopDetails.name}</span>
              </Row>
              <Row>
                <span className="k-detailSubHeader p-0">{desktopDetails.description}</span>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col >
              <Row className="k-inputText15">
                Security Group
              </Row>
              <Row className="disabled-text">
                <Dropdown>
                  <Dropdown.Toggle as={SelectGroupDropdownToggle}>
                    <Row>
                      <Col className="col-11 p-0 ">
                        { (groups && desktopDetails && desktopDetails.security && (desktopDetails.security !== "NULL")) ? 
                          groups.find((group: any)  => group.id === desktopDetails.security)?.name : 
                          "----"
                        }
                      </Col>
                    </Row>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={SelectGroupDropdownMenu} >
                    {groups && groups.map((group: any, index: number) => {
                      return (
                        <Dropdown.Item 
                          className="k-inputText12" 
                          key={`paneGroupEditForm.Dropdown.${index}`} 
                          onClick={(event: any) => toggleHandlers.handlePutDesktopSecurityGroupSubmit(event, group)}
                        >
                          {group.name}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
            <Col >
              <Row className="k-inputText15">
                Linked Project
              </Row>
              <Row className="disabled-text">
                ---
              </Row>
            </Col>
            <Col >
              <Row className="k-inputText15">
                Linked Profile
              </Row>
              <Row className="disabled-text">
                ---
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="col-6 pt">
          <Row className="k-bb pt-4 mt-2">
            <span className="k-detailControlHeader p-0">Desktop Details</span>
          </Row>
          <Row className="pt-2">
            <Col className="">
              <Row className=""><span className="k-inputText15 p-0">Status</span></Row>
              <Row className=""><span className={`k-inputText15 p-0 ${ desktopStatus }`}>{desktopStatus.toUpperCase()}</span></Row>
            </Col>
            <Col>
              <Row className="p-0"><span className="k-inputText15 p-0">Desktop Control</span></Row>
              <Row className="p-0"><ProductControlPanel  medialControlHandlers={mediaControlHandlers} desktopStatus={desktopStatus} iFrameID={`fullscreen-${desktopDetails.id}`} productUrl={desktopDetails.url}/></Row>
            </Col>
            <Col>
            <Row className="p-0"><span className="k-inputText15 p-0">Location</span></Row>
              <Row className="p-0"><span className="k-inputText15 p-0">{helpers.getReadableProductLocation(desktopDetails.region)}</span></Row>
            </Col>
            <Col>
            <Row className="p-0"><span className="k-inputText15 p-0">IP Address</span></Row>
              <Row className="p-0"><span className="k-inputText15 p-0">{desktopDetails.ipv4  || "----"}</span></Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="pt-4">
        <Col>
          <div id={`fullscreen-${desktopDetails.id}`} className="fullScreenContainer">
            <Row id="aa" xs="auto" className="toolbar">
              <Col className="pe-0"><img id="dd" src={hideContentBtn} alt={"Hide Content"} className="toobarButton pe-1" onClick={helpers.hideContent}/></Col>
              <Col className="ps-1 pe-0"><img id="ee" src={retractContentBtn} alt={"Redact"} className="toobarButton pe-1" onClick={helpers.exitFullScreen}/></Col>
              {/* <Col className="ps-1 pe-0 col-auto me-auto"><img id="ff" src={expandContentBtn} alt={"Expand"} className="toobarButton pe-1" onClick={() => helpers.enterFullScreen(`fullscreen-${desktopDetails.id}`)}/></Col> */}
              <Col className="ps-1 pe-0 col-auto me-auto"><a target="_blank" href={desktopDetails.url} rel="noreferrer"><img id="ff" src={expandContentBtn} alt={"Expand"} className="toobarButton pe-1"/> </a></Col>
              {/* <Col className="col-auto"><img id="gg" src={openMenuDownBtn} alt={"Dopdown"} className="toobarButton" onClick={() => helpers.showDropDown(`dropdown-${desktopDetails.id}`)}/></Col> */}
            </Row>

            <ProductContentPane
              productDetails={desktopDetails} 
              mediaControlHandlers={mediaControlHandlers} 
              productType={productType}
            />

            <Row id="priv-content" className="overlayBase justify-content-md-center ">
              <Col md="auto" className="k-iFrameCenter">
                <Row>
                  <Col className="col-3 pt-2">
                    <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg k-statusImg"/>
                  </Col>
                  <Col className="ps-0">
                    <h1>Locked</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4>Your {lowercaseProductType} has been locked</h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* 
            <ProductToolsPanel
              productDetails={desktopDetails}
              notes={desktopNotes}
              vault={desktopVault}
              decryptedVaultKey={decryptedVaultKey}
              preSelectedSecretId={preSelectedSecretId}

              vaultPasswordSubmitError={desktopVaultPasswordSubmitError}
              vaultSecretEditSubmitError={desktopVaultSecretEditSubmitError}

              vaultHandlers={productVaultHandlers}
              noteHandlers={productNoteHandlers}
            /> */}
          </div>
        </Col>
      </Row>
    </>
  );
};




export default Product;


