import React from 'react'
import {
  // useHistory, 
  Link 
} from 'react-router-dom'

import { Container, Col, Row, Button} from 'react-bootstrap'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'




const NotFound: React.FunctionComponent = () => {

  // const history = useHistory()

  // const handleRedirectToHome = () => {
  //   history.push('/')
  // }

  return (
  <>
    <main>
      {/* <section>
        <div>
        <img src={errorImage} width={224} height={224} alt="logo" />
        <br/>

        <h5>Sorry, Not Found!</h5>

        </div>
        <hr/>
        <button onClick={handleRedirectToHome}>
          <p>Return Home</p>
        </button>
      </section> */}
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <h1 className="text-primary mt-5">
                  Page not <span className="fw-bolder">found</span>
                </h1>
                <p className="lead my-4">
                  Oops! Its nice to see you but you need to really go home now.<br/>
                  If you keep coming here people will start to talk ....
                </p>
                <Link to="/">
                  <Button variant="primary" className="animate-hover" >
                    {/* <FontAwesomeIcon icon={faChevronLeft} className="animate-left-3 me-3 ms-2" /> */}
                    Go home
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  </>
  );
};

export default NotFound;
