import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Table, } from 'react-bootstrap';


import {
  productMediaDeleteIcon,
  productFavouriteIcon,
  productFavouriteYellowIcon,
} from "./../../assets/images";

import {
  variables
} from "./../../utilities"


const ProfilesTable: React.FunctionComponent<any> = (props: any) => {
  const { profiles, mediaControlHandlers} = props;

  console.log("PROFILES : ! : ", profiles);
  
  const ProfileTableRow = (props: any) => {
    const { id, name, createdDate, type, lastAccessed, description, metadata } = props;

    const handleToggleIsFavourite = async(event: any) => {
      event.preventDefault();

      if(metadata && metadata.favorite === true){
        mediaControlHandlers && await mediaControlHandlers.handleProfilesListToggleFavorite({
          resourceId: id, toggleType: variables.DEFAULT_API_METHODS.DELETE
        })
      } else {
        mediaControlHandlers && await mediaControlHandlers.handleProfilesListToggleFavorite({
          resourceId: id, toggleType: variables.DEFAULT_API_METHODS.PUT
        })
      }
      
      /**
       * Send API call via media controllers
       * E.g: await mediaControlHandlers.handleProductsListToggleFavourite(event, {id, name});
       * - Then remove use of state var, use Api result to toggle favourite highlight colour
       */
    };

    return (
      
        <tr >
          <td className="fw-bold"><Link to={`/profiles?id=${id}`} className="text-primary fw-bold">{name}<span> </span></Link></td>
          <td>{description }</td>
          <td>{type || "Profile"}</td>
          <td>{lastAccessed || "10:23 12-05-23"}</td>
          <td>{createdDate || "29-04-23"}</td>
          <td> 
            <Row>
              <Col md={1}>
                <Link to="" onClick={handleToggleIsFavourite}>
                  <span>
                    {(metadata && metadata.favorite === true)? 
                      <img 
                        className="k-ctrlImg" 
                        style={{width: "15px"}} 
                        src={productFavouriteYellowIcon} 
                        alt={"Favourite Icon"}
                      />
                      :
                      <img 
                        className="k-ctrlImg" 
                        style={{width: "20px"}} 
                        src={productFavouriteIcon} 
                        alt={"Favourite Icon"}
                      />
                    }
                  </span>
                </Link>
              </Col>
              <Col md={1}></Col>
              <Col md={1}>
                <Link to="" onClick={(event)=>{ mediaControlHandlers.handleProfilesListDelete(event, {id, name})}}>
                  <span>
                    <img className="k-ctrlImg" src={productMediaDeleteIcon} alt={"Delete Icon"}/>
                  </span>
                </Link>
              </Col>
            </Row>
          </td>
        </tr>
      

      
    );
  };

  return (

    <>
      <Row border="light" className="shadow-sm mb-4 ps-0">
        <Table responsive hover >
          <thead className="border-0">
            <tr>
              {/* <th className="border-0">#</th> */}
              <th className="border-0">Name</th>
              <th className="border-0">Description</th>
              <th className="border-0">Type</th>
              <th className="border-0">Last Accessed</th>
              <th className="border-0">Created</th>
              <th className="border-0">{" "}</th>
            </tr>
          </thead>
          <tbody>
            { profiles && profiles.map((profile: any, index: number) => 

              <ProfileTableRow key={`profile-${profile.id}-index-${index}`} {...profile}  />
            )}
          </tbody>
        </Table>
      </Row>

    </>
  );
};


const ProjectsTable: React.FunctionComponent<any> = (props: any) => {
  const { projects, mediaControlHandlers} = props;
  console.log("PROJECTS : ! : ", projects);
  
  const ProjectTableRow = (props: any) => {
    const { id, name, createdDate, type, lastAccessed, description, metadata } = props;

    const handleToggleIsFavourite = async(event: any) => {
      event.preventDefault();

      if(metadata && metadata.favorite === true){
        mediaControlHandlers && await mediaControlHandlers.handleProjectsListToggleFavorite({
          resourceId: id, toggleType: variables.DEFAULT_API_METHODS.DELETE
        })
      } else {
        mediaControlHandlers && await mediaControlHandlers.handleProjectsListToggleFavorite({
          resourceId: id, toggleType: variables.DEFAULT_API_METHODS.PUT
        })
      }
      /**
       * Send API call via media controllers
       * E.g: await mediaControlHandlers.handleProductsListToggleFavourite(event, {id, name});
       * - Then remove use of state var, use Api result to toggle favourite highlight colour
       */
    };

    return (
      <tr >
        <td className="fw-bold"><Link to={`/projects?id=${id}`} className="text-primary fw-bold">{name}<span> </span></Link></td>
        <td>{description }</td>
        <td>{type || "Project"}</td>
        <td>{lastAccessed || "10:23 12-05-23"}</td>
        <td>{createdDate || "29-04-23"}</td>
        <td> 
          <Row>
            <Col md={1}>
              <Link to="" onClick={handleToggleIsFavourite}>
                <span>
                  {(metadata && metadata.favorite === true)? 
                      <img 
                        className="k-ctrlImg" 
                        style={{width: "15px"}} 
                        src={productFavouriteYellowIcon} 
                        alt={"Favourite Icon"}
                      />
                      :
                      <img 
                        className="k-ctrlImg" 
                        style={{width: "20px"}} 
                        src={productFavouriteIcon} 
                        alt={"Favourite Icon"}
                      />
                    }
                </span>
              </Link>
            </Col>
            <Col md={1}></Col>
            <Col md={1}>
              <Link to="" onClick={(event)=>{ mediaControlHandlers.handleProjectsListDelete(event, {id, name})}}>
                <span>
                  <img className="k-ctrlImg" src={productMediaDeleteIcon} alt={"Delete Icon"}/>
                </span>
              </Link>
            </Col>
          </Row>
        </td>
      </tr>
    );
  };


  return (
    <>
      <Row border="light" className="shadow-sm mb-4 ps-0">
        <Table responsive hover >
          <thead className="border-0">
            <tr>
              {/* <th className="border-0">#</th> */}
              <th className="border-0">Name</th>
              <th className="border-0">Description</th>
              <th className="border-0">Type</th>
              <th className="border-0">Last Accessed</th>
              <th className="border-0">Created</th>
              <th className="border-0">{" "}</th>
            </tr>
          </thead>
          <tbody>
            { projects && projects.map((project: any, index: number) => 
              <ProjectTableRow key={`project-${project.id}-index-${index}`} {...project}  />
            )}
          </tbody>
        </Table>
      </Row>

    </>
  );
};


const DesktopsTable: React.FunctionComponent<any> = (props: any) => {
  const { desktops, mediaControlHandlers} = props;

  console.log("DESKTOPS : ", desktops)
  
  
  const DesktopTableRow = (props: any) => {
    const { id, name, username, system, state, description, isFavouriteFromApi, metadata } = props;
    console.log("ISFAVOURITE: ", isFavouriteFromApi);
    console.log("ISFAVOURITE: METADATA: ", metadata);

    // const [isFavourite, setIsFavourite] = useState(isFavouriteFromApi);

    const handleToggleIsFavourite = async (event: any) => {
      event.preventDefault();
      // setIsFavourite(!isFavourite);

      /**
       * Send API call via media controllers
       * E.g: await mediaControlHandlers.handleDesktopsListToggleFavourite(event, {id, name});
       * - Then remove use of state var, use Api result to toggle favourite highlight colour
       */
      // {resourceId: event.desktopId, toggleType: event.toggleType,

      if(metadata && metadata.favorite === true){
        mediaControlHandlers && await mediaControlHandlers.handleProductsListToggleFavorite({
          resourceId: id, toggleType: variables.DEFAULT_API_METHODS.DELETE
        })
      } else {
        mediaControlHandlers && await mediaControlHandlers.handleProductsListToggleFavorite({
          resourceId: id, toggleType: variables.DEFAULT_API_METHODS.PUT
        })
      }
    };

    return (
      
        <tr>
          {/* <td>
            <Link to="/desktops/id=1" className="text-primary fw-bold">desktop-{id}<span> </span></Link>
            
          </td> */}
          <td className="fw-bold"><Link to={`/desktops?id=${id}`} className="text-primary fw-bold">{name}<span> </span></Link></td>
          <td>{description }</td>
          <td>{system || "Windows OS"}</td>
          <td>{state || "Unknown"}</td>
          <td>{username || "Unknown"}</td>
          <td> 
            <Row>
              <Col md={1}>
                <Link to="" onClick={handleToggleIsFavourite}>
                  <span>
                    {(metadata && metadata.favorite === true)? 
                      <img 
                        className="k-ctrlImg" 
                        style={{width: "15px"}} 
                        src={productFavouriteYellowIcon} 
                        alt={"Favourite Icon"}
                      />
                      :
                      <img 
                        className="k-ctrlImg" 
                        style={{width: "20px"}} 
                        src={productFavouriteIcon} 
                        alt={"Favourite Icon"}
                      />
                    }
                  </span>
                </Link>
              </Col>
              <Col md={1}></Col>
              <Col md={1}>
                <Link to="" onClick={(event)=>{ mediaControlHandlers.handleDesktopsListDelete(event, {id, name})}}>
                  <span>
                    <img className="k-ctrlImg" src={productMediaDeleteIcon} alt={"Delete Icon"}/>
                  </span>
                </Link>
              </Col>
            </Row>
          </td>
        </tr>
    );
  };

  return (

    <>
      <Row border="light" className="shadow-sm mb-4 ps-0">
        <Table responsive hover >
          <thead className="border-0">
            <tr>
              {/* <th className="border-0">#</th> */}
              <th className="border-0">Name</th>
              <th className="border-0">Description</th>
              <th className="border-0">Type</th>
              <th className="border-0">State</th>
              <th className="border-0">Username</th>
              <th className="border-0">{" "}</th>
            </tr>
          </thead>
          <tbody>
            { desktops && desktops.map((desktop: any, index: number) => (
              <DesktopTableRow key={`desktop-${desktop.id}`} {...desktop}  />)
            )}
          </tbody>
        </Table>
      </Row>

    </>
  );
};


const BrowsersTable: React.FunctionComponent<any> = (props: any) => {
  const { browsers, mediaControlHandlers} = props;
  
  const BrowserTableRow = (props: any) => {
    const { id, name, system, state, description, metadata } = props;

    const handleToggleIsFavourite = async(event: any) => {
      event.preventDefault();

      if(metadata && metadata.favorite === true){
        mediaControlHandlers && await mediaControlHandlers.handleProductsListToggleFavorite({
          resourceId: id, toggleType: variables.DEFAULT_API_METHODS.DELETE
        })
      } else {
        mediaControlHandlers && await mediaControlHandlers.handleProductsListToggleFavorite({
          resourceId: id, toggleType: variables.DEFAULT_API_METHODS.PUT
        })
      }

      /**
       * Send API call via media controllers
       * E.g: await mediaControlHandlers.handleProductsListToggleFavourite(event, {id, name});
       * - Then remove use of state var, use Api result to toggle favourite highlight colour
       */
    };

    return (
      
        <tr>
          {/* <td>
            <Link to="/browsers?id=1" className="text-primary fw-bold">PRODUCT-{id}<span> </span></Link>
            
          </td> */}
          <td className="fw-bold"><Link to={`/browsers?id=${id}`} className="text-primary fw-bold">{name}<span> </span></Link></td>
          <td>{description }</td>
          <td>{system || "Unknown"}</td>
          <td>{state || "Unknown"}</td>
          <td> 
            <Row>
              <Col md={1}>
                <Link to="" onClick={handleToggleIsFavourite}>
                  <span>
                    {(metadata && metadata.favorite === true)? 
                      <img 
                        className="k-ctrlImg" 
                        style={{width: "15px"}} 
                        src={productFavouriteYellowIcon} 
                        alt={"Favourite Icon"}
                      />
                      :
                      <img 
                        className="k-ctrlImg" 
                        style={{width: "20px"}} 
                        src={productFavouriteIcon} 
                        alt={"Favourite Icon"}
                      />
                    }
                  </span>
                </Link>
              </Col>
              <Col md={1}></Col>
              <Col md={1}>
                <Link to="" onClick={(event)=>{ mediaControlHandlers.handleProductsListDelete(event, {id, name})}}>
                  <span>
                    <img className="k-ctrlImg" src={productMediaDeleteIcon} alt={"Delete Icon"}/>
                  </span>
                </Link>
              </Col>
            </Row>
          </td>
        </tr>
      

      
    );
  };

  return (

    <>
      <Row border="light" className="shadow-sm mb-4 ps-0">
        <Table responsive hover >
          <thead className="border-0">
            <tr>
              {/* <th className="border-0">#</th> */}
              <th className="border-0">Name</th>
              <th className="border-0">Description</th>
              <th className="border-0">Type</th>
              <th className="border-0">State</th>
              <th className="border-0">{" "}</th>
            </tr>
          </thead>
          <tbody>
            { browsers && browsers.map((browser: any, index: number) => 

              <BrowserTableRow key={`product-browser-${browser.id}`} {...browser}  />
            )}
          </tbody>
        </Table>
      </Row>

    </>
  );
};

const CommunicateTable: React.FunctionComponent<any> = (props: any) => {
  const { products, mediaControlHandlers} = props;
  
  
  const CommunicateTableRow = (props: any) => {
    const { id, name, system, state, description, metadata } = props;
    // const [isFavourite, setIsFavourite] = useState(isFavouriteFromApi);

    const handleToggleIsFavourite = async(event: any) => {
      event.preventDefault();
      // setIsFavourite(!isFavourite);

      if(metadata && metadata.favorite === true){
        mediaControlHandlers && await mediaControlHandlers.handleProductsListToggleFavorite({
          resourceId: id, toggleType: variables.DEFAULT_API_METHODS.DELETE
        })
      } else {
        mediaControlHandlers && await mediaControlHandlers.handleProductsListToggleFavorite({
          resourceId: id, toggleType: variables.DEFAULT_API_METHODS.PUT
        })
      }

      /**
       * Send API call via media controllers
       * E.g: await mediaControlHandlers.handleProductsListToggleFavourite(event, {id, name});
       * - Then remove use of state var, use Api result to toggle favourite highlight colour
       */
    };

    return (
      
        <tr>
          {/* <td>
            <Link to="/browsers?id=1" className="text-primary fw-bold">PRODUCT-{id}<span> </span></Link>
            
          </td> */}
          <td className="fw-bold"><Link to={`/browsers?id=${id}`} className="text-primary fw-bold">{name}<span> </span></Link></td>
          <td>{description }</td>
          <td>{system || "Unknown"}</td>
          <td>{state || "Unknown"}</td>
          <td> 
            <Row>
              <Col md={1}>
                <Link to="" onClick={handleToggleIsFavourite}>
                  <span>
                    {(metadata && metadata.favorite === true)? 
                      <img 
                        className="k-ctrlImg" 
                        style={{width: "15px"}} 
                        src={productFavouriteYellowIcon} 
                        alt={"Favourite Icon"}
                      />
                      :
                      <img 
                        className="k-ctrlImg" 
                        style={{width: "20px"}} 
                        src={productFavouriteIcon} 
                        alt={"Favourite Icon"}
                      />
                    }
                  </span>
                </Link>
              </Col>
              <Col md={1}></Col>
              <Col md={1}>
                <Link to="" onClick={(event)=>{ mediaControlHandlers.handleProductsListDelete(event, {id, name})}}>
                  <span>
                    <img className="k-ctrlImg" src={productMediaDeleteIcon} alt={"Delete Icon"}/>
                  </span>
                </Link>
              </Col>
            </Row>
          </td>
        </tr>
      

      
    );
  };

  return (

    <>
      <Row border="light" className="shadow-sm mb-4 ps-0">
        <Table responsive hover >
          <thead className="border-0">
            <tr>
              {/* <th className="border-0">#</th> */}
              <th className="border-0">Name</th>
              <th className="border-0">Description</th>
              <th className="border-0">Type</th>
              <th className="border-0">State</th>
              <th className="border-0">{" "}</th>
            </tr>
          </thead>
          <tbody>
            { products && products.map((service: any, index: number) => 

              <CommunicateTableRow key={`product-browser-${service.id}`} {...service}  />
            )}
          </tbody>
        </Table>
      </Row>

    </>
  );
};


export {
  ProfilesTable,
  ProjectsTable,
  DesktopsTable,
  BrowsersTable,
  CommunicateTable
}