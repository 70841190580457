import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Col, Row, Form, Button, Container} from 'react-bootstrap';
import { QRCodeSVG } from 'qrcode.react';

import { authHooks, authContextHelper } from './../../utilities'
import { Password, Username, Code as MfaCode } from '../../components/auth'
import {
 kuroLogo
} from "./../../assets/images";



const SignIn: React.FunctionComponent<{}> = () => {

  const { username, setUsername, usernameIsValid } = authHooks.useValidUsername('')
  const { password, setPassword, passwordIsValid } = authHooks.useValidPassword('')
  const { password: newPassword, setPassword: setNewPassword, passwordIsValid: newPasswordIsValid } = authHooks.useValidPassword('')
  const { password: comfirmNewPassword, setPassword: setConfirmNewPassword, passwordIsValid: comfirmNewPasswordIsValid } = authHooks.useValidPassword('')
  const { code: userMfaCode, setCode: setMfaCode, codeIsValid: isMfaCodeValid } = authHooks.useValidCode('')
  const { code: userMfaSetupCode, setCode: setMfaSetupCode, codeIsValid: isMfaSetupCodeValid } = authHooks.useValidCode('')

  const [isNewPasswordRequired, setNewPasswordRequired] = useState(false);
  const [isMfaRequired, setMfaRequired] = useState(false)
  const [isMfaSetupRequired, setMfaSetupRequired] = useState(false)
  const [mfaSetup, setMfaSetupObject] = useState({qrString: "", code: ""})
  const [error, setError] = useState<string>('')

  const isValid = !usernameIsValid || username.length === 0 || !passwordIsValid || password.length === 0

  const history = useHistory()

  const authContext = useContext(authContextHelper.AuthContext)

  
  const signInClicked = async (event: any) => {
    event.preventDefault();
    setError("");
    
    let response: any;
    try {
      
      response = await authContext.signInWithEmail(username, password, newPassword, userMfaCode, userMfaSetupCode)
      
      history.push("/")
    } catch (err: any) {
      
      if (err.code === 'UserNotConfirmedException') {
        history.push('/verify')
      
      } else if(err.code === 'UserRequiresNewPassword') {
        setNewPasswordRequired(true);
      
      } else if(err.code === 'UserRequiresMfaCode') {
        setMfaRequired(true);
      
      } else if(err.code === 'UserRequiresSignout') {
        await authContext.signOut();
        window.location.reload();
      
      } else if(err.code === 'UserRequiresMfaSetup') {
        setMfaSetupRequired(true);
        setMfaSetupObject(err.mfaSetup)
      } else {
        setError(err.message)
        // console.log("SIGN IN ERROR!: ", err);
      }
    }
  }

  const passwordResetClicked = async () => {
    history.push('/requestcode')
  }

  return (
    <>
    <main>
      <section className="d-flex align-items-center">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col xs={12} className="d-flex k-centerHV align-items-center justify-content-center">
              <div className="p-4 p-lg-5 w-100 fmxw-400">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <img src={kuroLogo} alt={"Icon"} className="ms-4 k-iconImg"/>
                  <br/>
                </div>
                <Form className="mt-4 " onSubmit={signInClicked}>
                  {(isMfaRequired || isMfaSetupRequired) ?(<>
                    <Form.Group id="email" className="mb-4">
                      {(isMfaSetupRequired) ?
                      (
                      <>
                        <QRCodeSVG value={mfaSetup && mfaSetup.qrString}/>
                        <br/>
                        <p> Alternatively, Enter this Code: {mfaSetup && mfaSetup?.code}</p>
                        {/* <Form.Label>MFA Code</Form.Label> */}
                        <MfaCode codeIsValid={isMfaSetupCodeValid} setCode={setMfaSetupCode} />
                      </>):
                      (<>
                        {/* <Form.Label>MFA Code</Form.Label> */}
                        <MfaCode codeIsValid={isMfaCodeValid} setCode={setMfaCode} />
                      
                      </>)
                      }
                      <br/>
                      {error? (<>
                        <small style={{color: "red"}}>{error}</small>
                      </>): null}

                      </Form.Group>
                  </>):
                  (<>
                    <Form.Group id="email" className="mb-4">
                      {/* <Form.Label>Email/Username</Form.Label> */}
                      <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="password" className="mb-4">
                        {/* <Form.Label>Password</Form.Label> */}
                        <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
                      </Form.Group>
                      
                      <br/>
                      {isNewPasswordRequired ? (
                        <>
                      <Form.Group id="password" className="mb-4">
                        {/* <Form.Label>New Password</Form.Label> */}
                        <Password label="New Password" passwordIsValid={newPasswordIsValid} setPassword={setNewPassword} />
                      </Form.Group>
                      <Form.Group id="password" className="mb-4">
                        {/* <Form.Label>Confirm New Password</Form.Label> */}
                        <Password label="Confirm New Password" passwordIsValid={(newPassword === comfirmNewPassword) && comfirmNewPasswordIsValid} setPassword={setConfirmNewPassword} />
                      </Form.Group>
                      <br/>
                      </>
                      ): null}

                      {error? (<>
                        <small style={{color: "red"}}>{error}</small>
                      </>): null}
                    </Form.Group>

                  </>)
                  }
                  <Button variant="primary" type="submit" className="w-100 kuro-button-primary" disabled={isValid} >
                    Sign In
                  </Button>

                  {/* <div >
                    {error}
                  </div> */}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
    </>
  )
}



export default SignIn
