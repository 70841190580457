import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, RouteProps, RouteComponentProps, Redirect, useHistory } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import {
  // Authentication
  SignIn, 

  // Error
  NotFound,

  // Dash Pages
  Dashboard,
  Desktops,

  // Admin Pages
  AdminDashboard,

} from './../pages';

import {
  PreLoader,
  SearchNavBar,
  Sidebar,
  ProductGenericAddModal,
} from './../components';


import { 
  authContextHelper, 
  api,
  bsgCrypto, 
  variables
} from './../utilities'

let appRouterHistory = createBrowserHistory();


interface KuroRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
};





const OpenRoute: React.FunctionComponent<KuroRouteProps> = ({
  component: Component,
  ...rest}) => {
  const [loaded, setLoaded] = useState(false);


  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={props =>  {
        
        let propsWithParams = {
          ...props,
          urlParameters: new URLSearchParams(props.location.search)
        }
        return(<Component {...propsWithParams} />)
      }
      }
    />
  );
};


const ProtectedRoute: React.FunctionComponent<KuroRouteProps> = ({
  component: Component,
  ...rest}) => {
  
  const auth = useContext(authContextHelper.AuthContext);
  const currentHistory = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [isShowAddProductModal, setAddProductModalShow] = useState(false);
  const [addProductModalSubmitError, setAddProductModalSubmitError] = useState<any>(null);

  const handleShowAddProductModal = (event: any) => {
    setAddProductModalShow(true)
  };

  const handleCloseAddProductModal = (event: any) => {
    setAddProductModalShow(false)
  };

  
  const handleProductAddSubmit = async(query: any) => {
    const vaultKeys = await bsgCrypto.VaultKey.createVaultKey([{ 
      username: auth.userProfile.username, 
      publicKey: auth.userKeys!.publicKey
    }]);
    
    const submitResponse =  await api.postProduct({
      body: query.body, 
      productType: variables.DEFAULT_PRODUCT_TYPES.DESKTOP.API_KEY,
      vaultKeys: vaultKeys
    });

    if ( submitResponse.result === "Failed"){
      setAddProductModalSubmitError(submitResponse.error);

    } else if ( submitResponse.result === "Success"){
      handleCloseAddProductModal(null);

      const handleRedirectAfterSubmission = () => {
        currentHistory.push(`/desktops?id=${submitResponse.id}`);
      }

      handleRedirectAfterSubmission()
    } else {

      console.log("ERROR RESPONSE: PRODUCTS PUT: ", submitResponse)

    }
  }

 


  useEffect(() => {
    const forceRefresh = async () => {
      await auth.refreshSessionInfo()
    }
    
    forceRefresh();

    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, [currentHistory]);


  return (
    <Route
      {...rest}
      render = { props =>  
        {
        
          let protectedGlobalProps = {
            ...props,
            urlParameters: new URLSearchParams(props.location.search),
            isShowAddProductModal,
            handleShowAddProductModal,
            handleCloseAddProductModal,
            setLoaded,
            currentHistory,
          }

          return(
            <>
              <ProductGenericAddModal
                show={isShowAddProductModal}
                submitError={addProductModalSubmitError}
                handleClose={handleCloseAddProductModal}
                handleModalFormSubmit={handleProductAddSubmit}
              />
              
              <PreLoader show={loaded ? false : true} />
              <Sidebar />
              <main className="content">
                <SearchNavBar {...protectedGlobalProps}/>
                <Component {...protectedGlobalProps}/>
              </main>
            </>
          )
        }
      
      }
    />
    
  );
};


const OpenRoutes: React.FunctionComponent = () => (
  <Switch>
    <OpenRoute exact path="/signin" component={SignIn} />
    <Route exact path="/error" component={NotFound} />
    <Redirect exact path="/" to="/signin" />
    <Redirect exact path="/*" to="/error" />
  </Switch>
)

const AuthenticatedRoutes: React.FunctionComponent = () => (
  <Switch>
    <ProtectedRoute exact path="/" component={Dashboard} />
    <ProtectedRoute exact path="/desktops" component={Desktops} />
    <ProtectedRoute exact path="/admin" component={AdminDashboard} />
    <Route exact path="/error" component={NotFound} />
    <Redirect exact path="/*" to="/error" />
  </Switch>
)


export {
  OpenRoutes,
  AuthenticatedRoutes,
  appRouterHistory,
}