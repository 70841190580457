import React, { useContext, useEffect, useState } from 'react';
import { Row, Container } from 'react-bootstrap';

import { 
  HighlightedProduct,
  GenericStatisticWidget, 
  HighlightedResourceAddButton,
} from './../../components'

import {
  resourceAddIcon,
} from "./../../assets/images";

import { 
  authContextHelper, 
  api, 
  variables 
} from './../../utilities';





const Dashboard: React.FunctionComponent = (props: any) => {
  const auth = useContext(authContextHelper.AuthContext);
  const { setLoaded } = props;

  const [desktops, setDesktops] = useState<any>([]);

  const generalStats = [
    {
      header: "Desktops", 
      description: "Active desktops", 
      numberCount: desktops && desktops.length
    }
  ]

  const getProducts = async() => {
    const productsData = await api.getProducts({});

    let desktopsData: any = [];

    productsData && productsData.forEach((product: any) => {
      switch(product.product){
        case variables.DEFAULT_PRODUCT_TYPES.DESKTOP.API_KEY:
          desktopsData.push(product);
          break;
        
        default:
          break;
      }
    });

    // Update product types 
    setDesktops(desktopsData);
  }


  useEffect(() => {
    setLoaded(false)
    
    getProducts();

    setLoaded(true);

  }, [setLoaded, auth]);


  console.log("DASHBOARD: USER KEYS FROM CONTEXT: ", auth.userKeys);


  return (
    <>
      <div>
        <h1> Console Home</h1>
        <h5>Hello {auth.userProfile.username}, what shall we do first?</h5>
        {/* <DropdownSelector/> */}
      </div>

      <br/>

      <Container className="ps-3 ms-0">
        <Row>
          { generalStats && generalStats.map((statObject, index) => {
            return (
              <GenericStatisticWidget key={`GSW-${index}`}
                header={statObject.header}
                description={statObject.description}
                numberCount={statObject.numberCount}
              />
            )
          })}
        </Row>    

        <div className="pt-5">
          <Row>
              <div className="ps-0">
                <h6> Desktops </h6>
              </div>
          </Row>
          <div>
            <Row>
              {desktops && desktops.map((desktop: any, index: number) => {
                return (
                  <HighlightedProduct key={`HR-${index}`}
                    header={desktop.name}
                    description={desktop.description}
                    linkPath={`/desktops?id=${desktop.id}`}
                    status={desktop.state}
                    product={desktop.product}
                    system={desktop.system}
                  />
                )
              })}
              
              
              <HighlightedResourceAddButton
                clickHandler={props.handleShowAddProductModal}
                header={"Add Desktop"}
                description={"Create a new desktop"}
                image={resourceAddIcon}
              />
              
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};



export default Dashboard;