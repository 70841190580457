import { useEffect, useRef } from 'react';

import { api, bsgCrypto, variables } from "./../";
import { UsernamePublicKey } from '../services/passwordVault';

export const DEFAULT_AWS_LOCATIONS: any = {
  "eu-west-2": "LONDON",
  "eu-central-1": "FRANKFURT",
  "ap-southeast-2": "SYDNEY",
  "us-east-2": "OHIO",
  "eu-north-1": "STOCKHOLM"
}



export const getReadableProductLocation = (region: string): string => {
  return DEFAULT_AWS_LOCATIONS[region] || "REGION N/A";
}


export const useInterval = (callback: any, delay: number) => {

  const savedCallback: any = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);


  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}



export const getCurrentDate = (separator='') => {

  let newDate = new Date()
  let day = newDate.getDate();
  let month = newDate.getMonth();
  let year = newDate.getFullYear();
  
  return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${day<10?`0${day}`:`${day}`}`
}

export const getConditionalDateTime = (unixtimeStamp:number) => {
  const currentTime = new Date();
  const passedTime = new Date(unixtimeStamp * 1000);

  if (currentTime.getDate() === passedTime.getDate()) {
    const time = `${passedTime.getHours().toString().padStart(2, '0')}:${passedTime.getMinutes().toString().padStart(2, '0')}`;
    return(time);
  } else {
    const date = `${passedTime.getMonth() + 1}/${passedTime.getDate()}/${passedTime.getFullYear()}`;
    return (date);
  }
};


export const removeNote = (elementID: string) => {
  const ele = document.getElementById(elementID);
  ele?.remove();
}


 export const hideContent = () => {
  var privContent = document.getElementById('priv-content');

  if(privContent?.classList.contains('hideContent')) {
    document.getElementById('priv-content')?.classList.remove('hideContent');
  } else {
    document.getElementById('priv-content')?.classList.add('hideContent');
  }
}

export const exitFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else {
    console.log("Cannot Exit Fullscreen");
  }
}

export const enterFullScreen = (elementID: string) => {
  var element = document.getElementById(elementID);
  if (element != null) {
  //("Found Element " +  iFrameID);
    if(element.requestFullscreen){
      element.requestFullscreen();
    }
  } else {
    console.log("Cannot find element" +  elementID);
  }
}

export const popNewWindow = (link: URL, elementID: string) => {
  window.open(link);
  const iFrame = document.getElementById(elementID);
  iFrame?.remove();
}

export const showDropDown = (id: string) => {
  var element = document.getElementById(id);

  if(element?.classList.contains('showDropdown')) {
    document.getElementById(id)?.classList.remove('showDropdown');
  } else {
    document.getElementById(id)?.classList.add('showDropdown');
  }
}


export const getRedableProductType = (type: string) => {
  return variables.DEFAULT_READABLE_PRODUCT_TYPES[type]?.NAME
}


/**
 * 
 * @param rawObject Object to be Filtered
 * @param keysToRemove Keys to be removed
 * @returns 
 */
export const filterObjectByRemovingKeys = (rawObject: any, keysToRemove: string[]) =>  {
  return Object.keys(rawObject)
  .filter(key => !keysToRemove.includes(key))
  .reduce((filteredObject: any, key: string) => {
    filteredObject[key] = rawObject[key];
    return filteredObject;
  }, {});
}

export async function createVaultKeysForSecurityGroup(securityGroupID: string) { 

  const getSecurityGroupResult = await api.getSecurityGroup({body: {id: securityGroupID}});

  let usernamesAndPublicKeys: Array<UsernamePublicKey> = [];

  if(getSecurityGroupResult.users?.length > 0) {
    for (const user of getSecurityGroupResult.users) {
      const getUserKeysResult = await api.getUserKeys({ username: user });
      const publicKey = getUserKeysResult.data?.public_key ? getUserKeysResult.data.public_key : getUserKeysResult.data
      let publicKeyAsCryptoKey = await globalThis.crypto.subtle.importKey(
        "spki",
        bsgCrypto.base64ToArrayBuffer(publicKey),
        { name: "RSA-OAEP", hash: "SHA-256" },
        true,
        ["wrapKey", "encrypt"]
      );

      usernamesAndPublicKeys.push({username: user, publicKey: publicKeyAsCryptoKey});
    }
  }

  return await bsgCrypto.VaultKey.createVaultKey(usernamesAndPublicKeys);
}

export async function checkAndAddMissingUserVaultKeys(resourceId: string, resourceType: string, vault: bsgCrypto.Vault) {
  const usersWithoutKeys = await api.getUsersWithoutKeys({
    resourceType: resourceType,
    resourceId: resourceId
  });

  if(usersWithoutKeys.result !== "Success") {
    console.log("Error getting users without keys")
    return;
  }

  if (usersWithoutKeys.data.result.length > 0) {

    let vaultKeys: Record<string, string> = {};

    for (const user of usersWithoutKeys.data.result) {
      const publicKey = await api.getUserKeys({ username: user });
      const wrappedVaultKey = await vault.wrapVaultKeyForUser(publicKey.data);
      vaultKeys[user] = wrappedVaultKey;
    }

    const patchingUserVaultKeysResult = await api.patchUserVaultKeys({
      resourceType: resourceType,
      resourceId: resourceId,
      body: {
        vault_keys: vaultKeys
      }
    });

    if(patchingUserVaultKeysResult.result !== "Success") {
      console.log("Error adding users to Vault")
    }
  }
}

