import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';


import './assets/scss/volt.scss'; // Import Volt Styling
import 'react-toastify/dist/ReactToastify.css'; // Toaster Styling

import { authContextHelper } from './utilities'
import { ScrollToTop } from './components'

import {
  AuthenticatedLayout,
  OpenLayout
} from './layouts'


const App: React.FunctionComponent = () => (
  <BrowserRouter>
    <ScrollToTop/>
    <ToastContainer />
    <authContextHelper.AuthProvider>
      <authContextHelper.AuthIsSignedIn>
        <AuthenticatedLayout/>
      </authContextHelper.AuthIsSignedIn>
      <authContextHelper.AuthIsNotSignedIn>
        <OpenLayout />
      </authContextHelper.AuthIsNotSignedIn>
    </authContextHelper.AuthProvider>
  </BrowserRouter>
)


export default App
